import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { AppButtonComponent } from './components/app-button/app-button.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MainComponent } from './pages/main/main.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

import { HomeComponent } from './views/1-home/home.component';
import { DataProvider } from '../providers/data/data';
import { CallapiService } from '../providers/rest/callapi.service';
import {
  HttpClientModule,
  HttpClient,
  HttpHandler,
  HttpClientJsonpModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MyAccountMailSendedComponent } from './views/1-myaccount/mail-sended/mail-sended.component';
import { MyAccountMailErrComponent } from './views/1-myaccount/mail-error/mail-error.component';
import { MyAccountMailCompComponent } from './views/1-myaccount/mail-comp/mail-comp.component';
import { MyAccountComponent } from './views/1-myaccount/myaccount.component';
import { MyAccountMailEditComponent } from './views/1-myaccount/mail-edit/mail-edit.component';

import { AccountListComponent } from './views/2-account/account-list/list.component';
import { AccountListDetailComponent } from './views/2-account/account-list-detail/detail.component';
import { AccountDeleteComponent } from './views/2-account/account-list-delete/delete.component';
import { AccountListCompComponent } from './views/2-account/account-list-modi-comp/comp.component';
import { AccountDeleteCompComponent } from './views/2-account/account-list-delete-comp/comp.component';
import { AccountCreateComponent } from './views/2-account/account-create/create.component';
import { AccountCreateCompleteComponent } from './views/2-account/account-create-comp/create-comp.component';

import { AnnouncementComponent } from './views/3-announcement/announcement.component';
import { AddComponent } from './views/3-announcement/add/add.component';
import { AddCompComponent } from './views/3-announcement/add-comp/add-comp.component';
import { AnnEditComponent } from './views/3-announcement/edit/edit.component';
import { AnnEditCompComponent } from './views/3-announcement/edit-comp/edit-comp.component';
import { AnnDeleteComponent } from './views/3-announcement/delete/delete.component';
import { AnnDeleteCompComponent } from './views/3-announcement/delete-comp/delete-comp.component';

import { ContractApplyComponent } from './views/4-contract-apply/apply-list.component';
import { ApplyEditComponent } from './views/4-contract-apply/apply-edit/apply-edit.component';
import { ApplyDetailComponent } from './views/4-contract-apply/apply-detail/apply-detail.component';
import { ApplyEditCompComponent } from './views/4-contract-apply/apply-edit-comp/apply-edit-comp.component';
import { ApplyModifyComponent } from './views/4-contract-apply/apply-modify/apply-modify.component';
import { ApplyModifyCompComponent } from './views/4-contract-apply/apply-modify-comp/apply-modify-comp.component';

import { ListDetailComponent } from './views/4-contract-list/contract-list-detail/list-detail.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ErrorComponent } from './pages/error/error.component';
import { DatepickerModule } from 'ng2-datepicker';
import { ViewsErrorComponent } from './views/error/error.component';
import { PointEditConfComponent } from './views/7-point/point-edit-conf/point-edit-conf.component';
import { DistributionListComponent } from './views/5-distribution-list/distribution-list.component';
import { PointComponent } from './views/7-point/point.component';
import { PointEditComponent } from './views/7-point/point-edit/point-edit.component';
import { PointEditCompComponent } from './views/7-point/point-edit-comp/point-edit-comp.component';
import { ContractListComponent } from './views/4-contract-list/list.component';
import { CancelComponent } from './views/4-contract-list/list-cancel/cancel.component';
import { CancelCompComponent } from './views/4-contract-list/list-cancel-comp/cancel.component';
import { ResultResendComponent } from './views/4-contract-list/result-resend/resend.component';
import { ResultResendCompComponent } from './views/4-contract-list/result-resend-comp/resend-comp.component';
import { ModifyEditComponent } from './views/4-contract-list/modify-edit/edit.component';
import { ModifyEditCompComponent } from './views/4-contract-list/modify-edit-comp/edit-comp.component';
import { UnpaidListComponent } from './views/6-unpaid-list/unpaid-list.component';
import { UnpaidCorpListComponent } from './views/6-unpaid-list/unpaid-corp-list/unpaid-corp-list.component';
import { UnpaidUserListComponent } from './views/6-unpaid-list/unpaid-user-list/unpaid-user-list.component';
import { UnpaidUserApplyComponent } from './views/6-unpaid-list/unpaid-user-apply-conf/unpaid-user-apply-conf.component';
import { UnpaidUserApplyCompleteComponent } from './views/6-unpaid-list/unpaid-user-apply-comp/unpaid-user-apply-comp.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CreatAccCompleteComponent } from './pages/create-account-comp/create-account-comp.component';
import { AccountListModifyComponent } from './views/2-account/account-list-modify/modify.component';
import { AccountResendComponent } from './views/2-account/account-list-resend/resend.component';
import { AccountResendCompComponent } from './views/2-account/account-list-resend-comp/comp.component';
import { RegistrantListComponent } from './views/8-registrant-list/registrant-list.component';
import { OrderApplyComponent } from './views/9-order-apply/order-apply.component';
import { OrderApplyCompleteComponent } from './views/9-order-apply/order-apply-comp/order-apply-comp.component';
import { OrderListComponent } from './views/9-order-list/order-list.component';
import { OrderListDetailComponent } from './views/9-order-list/order-list-detail/order-list-detail.component';
import { CodeListComponent } from './views/9-code-list/code-list.component';
import { TicketListComponent } from './views/9-code-list/ticket-list/ticket-list.component';
import { SaleCompanyListComponent } from './views/9-salecompany-list/salecompany-list.component';
import { SaleCompanyDetailComponent } from './views/9-salecompany-list/salecompany-detail/salecompany-detail.component';
import { SaleCompanyEditComponent } from './views/9-salecompany-list/salecompany-edit/salecompany-edit.component';
import { SaleCompanyEditCompComponent } from './views/9-salecompany-list/salecompany-edit-comp/salecompany-edit-comp.component';
import { SaleCompanyRegistComponent } from './views/9-salecompany-list/salecompany-regist/salecompany-regist.component';
import { SaleCompanyRegistCompComponent } from './views/9-salecompany-list/salecompany-regist-comp/salecompany-regist-comp.component';

registerLocaleData(localeEn, 'en-EN');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + new Date().getTime()
  );
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ErrorComponent,

    MyAccountComponent,
    MyAccountMailEditComponent,
    MyAccountMailSendedComponent,
    MyAccountMailErrComponent,
    MyAccountMailCompComponent,

    AccountCreateComponent,
    AccountCreateCompleteComponent,
    AccountListComponent,
    AccountListDetailComponent,
    AccountListModifyComponent,
    AccountListCompComponent,
    AccountDeleteCompComponent,
    AccountDeleteComponent,
    AccountResendComponent,
    AccountResendCompComponent,

    ContractApplyComponent,
    ApplyDetailComponent,
    ApplyEditComponent,
    ApplyEditCompComponent,
    ApplyModifyComponent,
    ApplyModifyCompComponent,

    AnnouncementComponent,
    AddComponent,
    AddCompComponent,
    AnnEditComponent,
    AnnEditCompComponent,
    AnnDeleteComponent,
    AnnDeleteCompComponent,

    ContractListComponent,
    ListDetailComponent,
    CancelComponent,
    CancelCompComponent,
    ResultResendComponent,
    ResultResendCompComponent,
    ModifyEditComponent,
    ModifyEditCompComponent,
    HomeComponent,
    AppButtonComponent,
    ViewsErrorComponent,

    PointComponent,
    PointEditComponent,
    PointEditConfComponent,
    PointEditCompComponent,

    DistributionListComponent,

    UnpaidListComponent,
    UnpaidCorpListComponent,
    UnpaidUserListComponent,
    UnpaidUserApplyComponent,
    UnpaidUserApplyCompleteComponent,

    OrderApplyComponent,
    OrderApplyCompleteComponent,
    OrderListComponent,
    OrderListDetailComponent,
    CodeListComponent,
    TicketListComponent,
    SaleCompanyListComponent,
    SaleCompanyDetailComponent,
    SaleCompanyEditComponent,
    SaleCompanyEditCompComponent,
    SaleCompanyRegistComponent,
    SaleCompanyRegistCompComponent,

    CreatAccCompleteComponent,

    RegistrantListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbModule,
    NgxSpinnerModule,
    DatepickerModule,
    DpDatePickerModule,
  ],
  providers: [DataProvider, CallapiService,DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
