<div class="main-padding">
  <head>
    <meta name="viewport" content="width=1024px, initial-scale=1" />
  </head>
  <h3 class="header-bottom header-text1">{{ 'SALECOMPANY_LIST.TITLE' | translate }}</h3>
  <!-- Section Edit -->
  <div id="edit">
    <div class="card-body">
      <p class="left-border header-text2">
        {{ 'SALECOMPANY_LIST.TITLE_EDIT' | translate }}
      </p>
    </div>
    <!-- 1 -->
    
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-3 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.BEFORE_DEST' | translate }}/{{
              'DETAILS.AFTER_DEST' | translate
            }}
          </h3>
        </div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <!-- Shine -->
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio" 
                style="margin-left: 25px;"
                (click)="selectCorporation('1')"
                *ngIf="Corporate_Status == '1'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c1"
                    name="customRadio"
                    checked
                  />
                  <label for="c1" class="custom-control-label ftext">{{
                    'DETAILS.BEFORE_DEST' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio" 
                style="margin-left: 25px;"
                (click)="selectCorporation('1')"
                *ngIf="Corporate_Status != '1'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c1"
                    name="customRadio"
                    checked
                  />
                  <label for="c1" class="custom-control-label ftext">{{
                    'DETAILS.BEFORE_DEST' | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio" 
                style="margin-left: 25px;"
                (click)="selectCorporation('2')"
                *ngIf="Corporate_Status == '2'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="customRadio4"
                    name="customRadio"
                    checked
                  />
                  <label for="customRadio5" class="custom-control-label ftext">{{
                    'DETAILS.AFTER_DEST' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio" 
                style="margin-left: 25px;"
                (click)="selectCorporation('2')"
                *ngIf="Corporate_Status != '2'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c2"
                    name="customRadio"
                  />
                  <label for="c2" class="custom-control-label ftext">{{
                    'DETAILS.AFTER_DEST' | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio" 
                style="margin-left: 25px;"
                (click)="selectCorporation('3')"
                *ngIf="Corporate_Status == '3'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c3"
                    name="customRadio"
                    checked
                  />
                  <label for="c3" class="custom-control-label ftext">{{
                    'DETAILS.OTHERS_DEST' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio" 
                style="margin-left: 25px;"
                (click)="selectCorporation('3')"
                *ngIf="Corporate_Status != '3'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c3"
                    name="customRadio"
                  />
                  <label for="c3" class="custom-control-label ftext">{{
                    'DETAILS.OTHERS_DEST' | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NAME_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-xl-10 col-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{
                  'CONTRACT.COMPANY_NAME_PLACEHOLDER' | translate
                }}"
                name="Company_Name"
                [(ngModel)]="Company_Name"
                (ngModelChange)="change()"
                maxlength="50"
              />
            </div>
          </div>
          <div class="col-12 top">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.COMPANY_NAME_KANA_PLACEHOLDER' | translate }}"
                name="Company_Name_Kana"
                [(ngModel)]="Company_Name_Kana"
                (ngModelChange)="onKeyKana(Company_Name_Kana)"
                autocomplete="off"
                maxlength="50"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-6 col-md-4 col-xl-4">
              <input
                type="text"
                class="form-control"
                maxlength="7"
                minlength="7"
                placeholder="{{ 'CONTRACT.POST_PLACEHOLDER' | translate }}"
                name="Post_Code"
                [(ngModel)]="Post_Code"
                (ngModelChange)="change()"
                (keyup)="onKey($event.target.value)"
                onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
              />
            </div>
            <div class="display">
              <h3 class="font">{{ 'CONTRACT.TEXT_1' | translate }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row top">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"></h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.ADDRESS_1_PLACEHOLDER' | translate }}"
                name="Address_1"
                [(ngModel)]="Address_1"
                (ngModelChange)="change()"
                maxlength="50"
              />
            </div>
          </div>
          <div class="col-12 top">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.ADDRESS_2_PLACEHOLDER' | translate }}"
                name="Address_2"
                [(ngModel)]="Address_2"
                (ngModelChange)="change()"
                maxlength="20"
              />
            </div>
          </div>
          <div class="col-12 top lef">
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.ADDRESS_3_PLACEHOLDER' | translate }}"
                name="Address_3"
                [(ngModel)]="Address_3"
                (ngModelChange)="change()"
                maxlength="30"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.TELEPHONE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-8 col-md-6 col-xl-6">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.TELEPHONE_PLACEHOLDER' | translate }}"
                name="TelePhone"
                [(ngModel)]="TelePhone"
                (ngModelChange)="change()"
                maxlength="15"
                onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
              />
            </div>
            <div class="display">
              <h3 class="font">{{ 'CONTRACT.TEXT_1' | translate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- email -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'SALECOMPANY_LIST.EMAIL' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="email"
                class="form-control"
                placeholder="{{ 'SALECOMPANY_LIST.EMAIL' | translate }}"
                (ngModelChange)="change()"
                name="Email"
                [(ngModel)]="Email"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 業種・業態 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.BUSINESS_TYPE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-10 col-xl-10">
              <select class="form-control" [(ngModel)]="business_type">
                <option *ngFor="let i of BusinessType" value="{{i.code}}">
                  {{i.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 5 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.CHARGER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div
              class="custom-control display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
              style="width: 211px;"
            >
              <input
                type="text"
                class="form-control names"
                placeholder="{{ 'CONTRACT.SURNAME_PLACEHOLDER' | translate }}"
                name="Last_Name"
                [(ngModel)]="Last_Name"
                (ngModelChange)="change()"
                maxlength="10"
              />
            </div>
            <div class="display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
            style="width: 211px;">
              <input
                type="text"
                class="form-control names1"
                placeholder="{{ 'CONTRACT.NAME_PLACEHOLDER' | translate }}"
                name="Name"
                [(ngModel)]="Name"
                (ngModelChange)="change()"
                maxlength="10"
              />
            </div>
          </div>
          <div class="col-12 top">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div
              class="custom-control display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
              style="width: 211px;"
            >
              <input
                type="text"
                class="form-control names"
                placeholder="{{
                  'CONTRACT.SURNAME_KANA_PLACEHOLDER' | translate
                }}"
                name="Last_Name_Kana"
                [(ngModel)]="Last_Name_Kana"
                (ngModelChange)="onKeyLastNameKana(Last_Name_Kana)"
                maxlength="20"
              />
            </div>
            <div class="display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
            style="width: 211px;">
              <input
                type="text"
                class="form-control names1"
                placeholder="{{ 'CONTRACT.NAME_KANA_PLACEHOLDER' | translate }}"
                name="Name_Kana"
                [(ngModel)]="Name_Kana"
                (ngModelChange)="onKeyNameKana(Name_Kana)"
                maxlength="20"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 9 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest" style="margin-top: 20px;">
          <h3 class="card-title">
            {{ 'SALECOMPANY_LIST.USAGE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span style="position: absolute; margin-top: 35px;" class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="card-body custom-control display types" style="padding-left: 45px;">
              <div class="display col-xl-4 col-md-4">
                <button type="button" id="type1" class="btn-box" (click)="changeType('1')">
                  {{ 'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE_1_BUTTON' | translate }}<br/>
                  {{'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE_2_BUTTON' | translate }}
                </button>
              </div>
              <div class="custom-control display col-xl-4 col-md-4">
                <button type="button" id="type2" class="btn-box-grey" (click)="changeType('2')">
                  {{ 'CONTRACT.HEALTH_INSURANCE_1_BUTTON' | translate }}<br/>
                  {{ 'CONTRACT.HEALTH_INSURANCE_2_BUTTON' | translate }}
                </button>
              </div>
              <div class="custom-control display col-xl-4 col-md-4">
                <button type="button" id="type3" class="btn-box-grey" (click)="changeType('3')">
                  {{ 'CONTRACT.WELFARE_AGENCY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="text-center">
      <div class="text1 text-center" style="height: 34px;min-width: 874px;">
        <p style="display: none;" id="error"></p>
      </div>
      <div class="two-btn">
        <div class="display">
          <a (click)="back(1)" class="btn-grey"
            >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="input()" class="btn-pink"
            >{{ 'COMMON.COMMON_NEXT_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- End Buttons -->
  </div>
  <!-- Edit Section End -->
  <!-- Confirm Section -->
  <section class="content" id="confirm" style="display: none;">
    <div class="card-body">
      <p class="left-border header-text2">
        {{ 'SALECOMPANY_LIST.TITLE_CONFIRM' | translate }}
      </p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.BEFORE_DEST' | translate }}/{{
              'DETAILS.AFTER_DEST' | translate
            }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title" *ngIf="Corporate_Status == '1'">
                {{ 'DETAILS.BEFORE_DEST' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporate_Status == '2'">
                {{ 'DETAILS.AFTER_DEST' | translate }}
              </h3>
              <h3 class="card-title long-text" *ngIf="Corporate_Status == '3'">
                {{ 'DETAILS.OTHERS_DEST' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NAME_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title long-text">{{ Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest"></div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="margin-top: -25px" class="card-title long-text">
                {{ Company_Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">
                {{ Post_Code }}<br />{{ Address_1 }}<br />
                {{ Address_2 }}{{ Address_3 }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.TELEPHONE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ TelePhone }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'SALECOMPANY_LIST.EMAIL' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ Email }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 7 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.BUSINESS_TYPE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{Business_Type_Name}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 8 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.CHARGER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">{{ Name }}</h3>
            </div>
          </div>
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name_Kana }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 9 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'SALECOMPANY_LIST.USAGE' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporation_Form == '1'">
                {{ 'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '2'">
                {{ 'CONTRACT.HEALTH_INSURANCE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '3'">
                {{ 'CONTRACT.WELFARE_AGENCY' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="text1 text-center">
      <p id="conferr" class="error-text">
        
      </p>
    </div>
    <div class="text-center">
      <div class="two-btn1">
        <div class="display">
          <a (click)="back(2)" class="btn-grey"
            >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="confirm()" class="btn-pink"
            >{{ 'SALECOMPANY_LIST.MODIFY_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Buttons End -->
  </section>
  <!-- Confirm section end -->
</div>

<!-- Loader -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>
