import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeComponent } from './views/1-home/home.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

import { MyAccountComponent } from './views/1-myaccount/myaccount.component';
import { MyAccountMailEditComponent } from './views/1-myaccount/mail-edit/mail-edit.component';
import { MyAccountMailSendedComponent } from './views/1-myaccount/mail-sended/mail-sended.component';
import { MyAccountMailErrComponent } from './views/1-myaccount/mail-error/mail-error.component';
import { MyAccountMailCompComponent } from './views/1-myaccount/mail-comp/mail-comp.component';

import { AccountCreateComponent } from './views/2-account/account-create/create.component';
import { AccountCreateCompleteComponent } from './views/2-account/account-create-comp/create-comp.component';
import { AccountListDetailComponent } from './views/2-account/account-list-detail/detail.component';
import { AccountListCompComponent } from './views/2-account/account-list-modi-comp/comp.component';
import { AccountListComponent } from './views/2-account/account-list/list.component';
import { AccountDeleteComponent } from './views/2-account/account-list-delete/delete.component';
import { AccountDeleteCompComponent } from './views/2-account/account-list-delete-comp/comp.component';

import { AnnouncementComponent } from './views/3-announcement/announcement.component';
import { AddComponent } from './views/3-announcement/add/add.component';
import { AddCompComponent } from './views/3-announcement/add-comp/add-comp.component';
import { AnnEditComponent } from './views/3-announcement/edit/edit.component';
import { AnnEditCompComponent } from './views/3-announcement/edit-comp/edit-comp.component';
import { AnnDeleteComponent } from './views/3-announcement/delete/delete.component';
import { AnnDeleteCompComponent } from './views/3-announcement/delete-comp/delete-comp.component';

import { ContractApplyComponent } from './views/4-contract-apply/apply-list.component';
import { ApplyDetailComponent } from './views/4-contract-apply/apply-detail/apply-detail.component';
import { ApplyEditComponent } from './views/4-contract-apply/apply-edit/apply-edit.component';
import { ApplyEditCompComponent } from './views/4-contract-apply/apply-edit-comp/apply-edit-comp.component';
import { ApplyModifyComponent } from './views/4-contract-apply/apply-modify/apply-modify.component';
import { ApplyModifyCompComponent } from './views/4-contract-apply/apply-modify-comp/apply-modify-comp.component';

import { ListDetailComponent } from './views/4-contract-list/contract-list-detail/list-detail.component';
import { ErrorComponent } from './pages/error/error.component';;
import { ViewsErrorComponent } from './views/error/error.component';
import { PointEditConfComponent } from './views/7-point/point-edit-conf/point-edit-conf.component';
import { DistributionListComponent } from './views/5-distribution-list/distribution-list.component';
import { PointEditCompComponent } from './views/7-point/point-edit-comp/point-edit-comp.component';
import { PointEditComponent } from './views/7-point/point-edit/point-edit.component';
import { PointComponent } from './views/7-point/point.component';
import { ContractListComponent } from './views/4-contract-list/list.component';
import { CancelComponent } from './views/4-contract-list/list-cancel/cancel.component';
import { CancelCompComponent } from './views/4-contract-list/list-cancel-comp/cancel.component';
import { ResultResendComponent } from './views/4-contract-list/result-resend/resend.component';
import { ResultResendCompComponent } from './views/4-contract-list/result-resend-comp/resend-comp.component';
import { ModifyEditComponent } from './views/4-contract-list/modify-edit/edit.component';
import { ModifyEditCompComponent } from './views/4-contract-list/modify-edit-comp/edit-comp.component';
import { UnpaidListComponent } from './views/6-unpaid-list/unpaid-list.component';
import { UnpaidCorpListComponent } from './views/6-unpaid-list/unpaid-corp-list/unpaid-corp-list.component';
import { UnpaidUserListComponent } from './views/6-unpaid-list/unpaid-user-list/unpaid-user-list.component';
import { UnpaidUserApplyComponent } from './views/6-unpaid-list/unpaid-user-apply-conf/unpaid-user-apply-conf.component';
import { UnpaidUserApplyCompleteComponent } from './views/6-unpaid-list/unpaid-user-apply-comp/unpaid-user-apply-comp.component';
import { CreatAccCompleteComponent } from './pages/create-account-comp/create-account-comp.component';
import { AccountListModifyComponent } from './views/2-account/account-list-modify/modify.component';
import { AccountResendComponent } from './views/2-account/account-list-resend/resend.component';
import { AccountResendCompComponent } from './views/2-account/account-list-resend-comp/comp.component';
import { RegistrantListComponent } from './views/8-registrant-list/registrant-list.component';
import { ErrorNetworkComponent } from './pages/networkError/error.component';
import { OrderApplyComponent } from './views/9-order-apply/order-apply.component';
import { OrderApplyCompleteComponent } from './views/9-order-apply/order-apply-comp/order-apply-comp.component';
import { OrderListComponent } from './views/9-order-list/order-list.component';
import { OrderListDetailComponent } from './views/9-order-list/order-list-detail/order-list-detail.component';
import { CodeListComponent } from './views/9-code-list/code-list.component';
import { TicketListComponent } from './views/9-code-list/ticket-list/ticket-list.component';
import { SaleCompanyListComponent } from './views/9-salecompany-list/salecompany-list.component';
import { SaleCompanyDetailComponent } from './views/9-salecompany-list/salecompany-detail/salecompany-detail.component';
import { SaleCompanyEditComponent } from './views/9-salecompany-list/salecompany-edit/salecompany-edit.component';
import { SaleCompanyEditCompComponent } from './views/9-salecompany-list/salecompany-edit-comp/salecompany-edit-comp.component';
import { SaleCompanyRegistComponent } from './views/9-salecompany-list/salecompany-regist/salecompany-regist.component';
import { SaleCompanyRegistCompComponent } from './views/9-salecompany-list/salecompany-regist-comp/salecompany-regist-comp.component';
const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      // views/1-myaccount
      {
        path: 'wf-myaccount-edit',
        component: MyAccountComponent,
      },
      {
        path: 'wf-myaccount-mail-edit',
        component: MyAccountMailEditComponent,
      },
      {
        path: 'wf-myaccount-mail-sended',
        component: MyAccountMailSendedComponent,
      },
      // views/2-account
      {
        path: 'wf-account-create',
        component: AccountCreateComponent,
      },
      {
        path: 'wf-account-create-comp',
        component: AccountCreateCompleteComponent,
      },
      {
        path: 'wf-account-list',
        component: AccountListComponent,
      },
      {
        path: 'wf-account-list-detail',
        component: AccountListDetailComponent,
      },
      {
        path: 'wf-account-list-modify',
        component: AccountListModifyComponent,
      },
      {
        path: 'wf-account-list-modi-comp',
        component: AccountListCompComponent,
      },
      {
        path: 'wf-account-list-delete',
        component: AccountDeleteComponent,
      },
      {
        path: 'wf-account-list-delete-comp',
        component: AccountDeleteCompComponent,
      },
      {
        path: 'wf-account-list-resend',
        component: AccountResendComponent,
      },
      {
        path: 'wf-account-list-resend-comp',
        component: AccountResendCompComponent,
      },
      // views/3-announcement
      {
        path: 'wf-announcement',
        component: AnnouncementComponent,
      },
      {
        path: 'wf-announcement-add',
        component: AddComponent,
      },
      {
        path: 'wf-announcement-add-comp',
        component: AddCompComponent,
      },
      {
        path: 'wf-announcement-edit',
        component: AnnEditComponent,
      },
      {
        path: 'wf-announcement-edit-comp',
        component: AnnEditCompComponent,
      },
      {
        path: 'wf-announcement-delete',
        component: AnnDeleteComponent,
      },
      {
        path: 'wf-announcement-delete-comp',
        component: AnnDeleteCompComponent,
      },
      // views/4-contract-apply
      {
        path: 'wf-contract-apply-list',
        component: ContractApplyComponent,
      },
      {
        path: 'wf-contract-apply-detail',
        component: ApplyDetailComponent,
      },
      {
        path: 'wf-contract-apply-edit',
        component: ApplyEditComponent,
      },
      {
        path: 'wf-contract-apply-edit-comp',
        component: ApplyEditCompComponent,
      },
      {
        path: 'wf-contract-apply-modify',
        component: ApplyModifyComponent,
      },
      {
        path: 'wf-contract-apply-modify-comp',
        component: ApplyModifyCompComponent,
      },
      // views/4-contract-list
      {
        path: 'wf-contract-list',
        component: ContractListComponent,
      },
      {
        path: 'wf-contract-list-detail',
        component: ListDetailComponent,
      },
      {
        path: 'wf-contract-list-cancel-conf',
        component: CancelComponent,
      },
      {
        path: 'wf-contract-list-cancel-comp',
        component: CancelCompComponent,
      },
      {
        path: 'wf-contract-result-resend',
        component: ResultResendComponent,
      },
      {
        path: 'wf-contract-result-resend-comp',
        component: ResultResendCompComponent,
      },
      {
        path: 'wf-contract-modify-edit',
        component: ModifyEditComponent,
      },
      {
        path: 'wf-contract-modify-comp',
        component: ModifyEditCompComponent,
      },
      {
        path: 'wf-contract-err',
        component: ViewsErrorComponent,
      },
      // views/5-distribution-list
      // {
      //   path: 'wf-distribution-list',
      //   component: DistributionListComponent,
      // },
      // views/6-unpaid-list
      // {
      //   path: 'wf-unpaid-list',
      //   component: UnpaidListComponent,
      // },
      // {
      //   path: 'wf-unpaid-corp-list',
      //   component: UnpaidCorpListComponent,
      // },
      // {
      //   path: 'wf-unpaid-user-list',
      //   component: UnpaidUserListComponent,
      // },
      // {
      //   path: 'wf-unpaid-user-apply-conf',
      //   component: UnpaidUserApplyComponent,
      // },
      // {
      //   path: 'wf-unpaid-user-apply-comp',
      //   component: UnpaidUserApplyCompleteComponent,
      // },
      // views/7-point
      {
        path: 'wf-point',
        component: PointComponent,
      },
      {
        path: 'wf-point-edit',
        component: PointEditComponent,
      },
      {
        path: 'wf-point-edit-conf',
        component: PointEditConfComponent,
      },
      {
        path: 'wf-point-edit-comp',
        component: PointEditCompComponent,
      },
      {
        path: 'wf-registrant-list',
        component: RegistrantListComponent,
      },
      // views/9-order-apply
      {
        path: 'wf-order-apply',
        component: OrderApplyComponent,
      },
      {
        path: 'wf-order-apply-comp',
        component: OrderApplyCompleteComponent,
      },
      {
        path: 'wf-order-list',
        component: OrderListComponent,
      },
      {
        path: 'wf-order-list-detail',
        component: OrderListDetailComponent,
      },
      {
        path: 'wf-code-list',
        component: CodeListComponent,
      },
      {
        path: 'wf-ticket-list',
        component: TicketListComponent,
      },
      {
        path: 'wf-salecompany-list',
        component: SaleCompanyListComponent,
      },
      {
        path: 'wf-salecompany-detail',
        component: SaleCompanyDetailComponent,
      },
      {
        path: 'wf-salecompany-edit',
        component: SaleCompanyEditComponent,
      },
      {
        path: 'wf-salecompany-edit-comp',
        component: SaleCompanyEditCompComponent,
      },
      {
        path: 'wf-salecompany-regist',
        component: SaleCompanyRegistComponent,
      },
      {
        path: 'wf-salecompany-regist-comp',
        component: SaleCompanyRegistCompComponent,
      },
      {
        path: '',
        component: HomeComponent,
      },
    ],
  },
  {
    path: 'wf-login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'wf-change-pw',
    component: ChangePasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'wf-forgot-pw',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'wf-error',
    component: ErrorComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'network-error',
    component: ErrorNetworkComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'create-account-comp',
    component: CreatAccCompleteComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'wf-myaccount-mail-err',
    component: MyAccountMailErrComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'wf-myaccount-mail-comp',
    component: MyAccountMailCompComponent,
    canActivate: [NonAuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',useHash: true, })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
