import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import { console_log, DataProvider } from 'src/providers/data/data';
import { Router } from '@angular/router';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  public sidebarMenuOpened = true;
  public treeOpened = false;
  public pointOpened = false;
  public listOpened = false;
  public applyOpened = false;
  public name;
  public wf_unpaid_list = '/wf-unpaid-list';

  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public appService: AppService,
    private renderer: Renderer2,
    public data: DataProvider,
    public rest: CallapiService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { }
  public two = '2';
  public pages;

  ngOnInit() {
    this.spinner.show();
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        console_log(res);
        if (res.code == 'S000001') {
          this.pages = res.data;
          for (let i = 0; i < this.pages.length; i++) {
            if (this.pages[i].pagename == 'wf-home') {
              this.data.menuHomePage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuHome = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuHome = false;
              }
            }
            if (this.pages[i].pagename == 'wf-account-create') {
              this.data.menuCreatePage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuCreate = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuCreate = false;
              }
            }
            if (this.pages[i].pagename == 'wf-account-list') {
              this.data.menuAccountListPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuAccountList = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuAccountList = false;
              }
            }
            if (this.pages[i].pagename == 'wf-contract-apply-list') {
              this.data.menuApplyListPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuApplyList = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuApplyList = false;
              }
            }
            if (this.pages[i].pagename == 'wf-contract-list') {
              this.data.menuContractListPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuContractList = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuContractList = false;
              }
            }
            if (this.pages[i].pagename == 'wf-point') {
              this.data.menuPointPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuPoint = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuPoint = false;
              }
            }
            if (this.pages[i].pagename == 'wf-distribution-list') {
              this.data.menuDistributionListPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuDistributionList = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuDistributionList = false;
              }
            }
            if (this.pages[i].pagename == 'wf-registrant-list') {
              this.data.menuRegistrantListPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuRegistrantList = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuRegistrantList = false;
              }
            }
            if (this.pages[i].pagename == 'wf-unpaid-list') {
              this.data.menuUnpaidListPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuUnpaidList = true;
                this.rest.Get_WFAccount(this.data.uuid).subscribe(
                  (res) => {
                    console_log(res);
                    if (res.data.management_company_code !== 'HC01') {
                      this.wf_unpaid_list = '/wf-unpaid-corp-list';
                    }
                  },
                  (err) => {
                    console_log(err);
                  }
                );
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuUnpaidList = false;
              }
            }
          }

          if (this.sidebarMenuOpened) {
            this.renderer.removeClass(
              document.querySelector('aside'),
              'collapse-width'
            );
            this.renderer.removeClass(
              document.querySelector('app-root'),
              'sidebar-collapse'
            );
            this.renderer.addClass(
              document.querySelector('app-root'),
              'sidebar-open'
            );
            this.sidebarMenuOpened = true;
            document.getElementById('sideMenu1').classList.add('arrow');
            document.getElementById('sideMenu1').classList.remove('arrow1');
          } else {
            this.renderer.addClass(document.querySelector('aside'), 'collapse-width');
            this.renderer.removeClass(
              document.querySelector('app-root'),
              'sidebar-open'
            );
            this.renderer.addClass(
              document.querySelector('app-root'),
              'sidebar-collapse'
            );
            this.sidebarMenuOpened = false;
            document.getElementById('sideMenu1').classList.remove('arrow');
            document.getElementById('sideMenu1').classList.add('arrow1');
          }
          this.spinner.hide();
        }
      }, (err) => {
        console_log(err)
        if (err.error) {
          this.spinner.hide();
          window.localStorage.clear();
          this.router.navigate(['/wf-error', { code: err.error.error.code, id: this.data.uuid },]);
        } else {
          this.spinner.hide();
          window.localStorage.clear();
          this.router.navigate(['/wf-error', { code: 'CORS', id: this.data.uuid },]);
        }
      }
    );
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }

  collapseTree() {
    if (!this.treeOpened) {
      this.treeOpened = true;
      document.getElementById('treeView').style.display = 'block';
      document.getElementById('collapsable').classList.remove('active');
      document.getElementById('collapsable').classList.remove('nav-link');
      document.getElementById('collapsable').classList.add('nav-link-new');
      document.getElementById('collapsable-text').classList.add('drop1');
    } else {
      this.treeOpened = false;
      document.getElementById('treeView').style.display = 'none';
      document.getElementById('collapsable').classList.remove('active');
      document.getElementById('collapsable').classList.add('nav-link');
      document.getElementById('collapsable').classList.remove('nav-link-new');
      document.getElementById('collapsable-text').classList.remove('drop1');
    }
  }
  collapseList() {
    if (!this.listOpened) {
      this.listOpened = true;
      document.getElementById('treeList').style.display = 'block';
      document.getElementById('collapsable1').classList.remove('active');
      document.getElementById('collapsable1').classList.remove('nav-link');
      document.getElementById('collapsable1').classList.add('nav-link-new');
      document.getElementById('collapsable1-text').classList.add('drop1');
    } else {
      this.listOpened = false;
      document.getElementById('treeList').style.display = 'none';
      document.getElementById('collapsable1').classList.remove('active');
      document.getElementById('collapsable1').classList.add('nav-link');
      document.getElementById('collapsable1').classList.remove('nav-link-new');
      document.getElementById('collapsable1-text').classList.remove('drop1');
    }
  }
  collapsePoint() {
    if (!this.pointOpened) {
      this.pointOpened = true;
      document.getElementById('pointView').style.display = 'block';
      document.getElementById('collapsable_point').classList.remove('active');
      document.getElementById('collapsable_point').classList.remove('nav-link');
      document.getElementById('collapsable_point').classList.add('nav-link-new');
      document.getElementById('collapsable_point_text').classList.add('drop1');
    } else {
      this.pointOpened = false;
      document.getElementById('pointView').style.display = 'none';
      document.getElementById('collapsable_point').classList.remove('active');
      document.getElementById('collapsable_point').classList.add('nav-link');
      document.getElementById('collapsable_point').classList.remove('nav-link-new');
      document.getElementById('collapsable_point_text').classList.remove('drop1');
    }
  }
  collapseApply() {
    if (!this.applyOpened) {
      this.applyOpened = true;
      document.getElementById('applyView').style.display = 'block';
      document.getElementById('apply1').classList.remove('active');
      document.getElementById('apply1').classList.remove('nav-link');
      document.getElementById('apply1').classList.add('nav-link-new');
      document.getElementById('apply1-text').classList.add('drop1');
    } else {
      this.applyOpened = false;
      document.getElementById('applyView').style.display = 'none';
      document.getElementById('apply1').classList.remove('active');
      document.getElementById('apply1').classList.add('nav-link');
      document.getElementById('apply1').classList.remove('nav-link-new');
      document.getElementById('apply1-text').classList.remove('drop1');
    }
  }
  checkMenuDropDownList(id) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(document.querySelector('aside'), 'transform');
      document.getElementById('sidebar-overlay').style.display = 'none';
      this.data.sidebarMenuOpened = false;
    }
    if (id == 1 || id == 2) {
      if (document.getElementById('treeList'))
        document.getElementById('treeList').style.display = 'none';
      if (document.getElementById('pointView'))
        document.getElementById('pointView').style.display = 'none';
      if (document.getElementById('applyView'))
        document.getElementById('applyView').style.display = 'none';
        this.pointOpened = false;
        this.listOpened = false;
        this.applyOpened =false;
    } else if (id == 3 || id == 4 || id == 5 || id == 6 || id == 8) {
      if (document.getElementById('treeView'))
        document.getElementById('treeView').style.display = 'none';
      if (document.getElementById('pointView'))
        document.getElementById('pointView').style.display = 'none';
      if (document.getElementById('applyView'))
        document.getElementById('applyView').style.display = 'none';
        this.treeOpened = false;
        this.pointOpened = false;
        this.applyOpened =false;
    } else if (id == 7) {
      if (document.getElementById('treeView'))
        document.getElementById('treeView').style.display = 'none';
      if (document.getElementById('treeList'))
        document.getElementById('treeList').style.display = 'none';
      if (document.getElementById('applyView'))
        document.getElementById('applyView').style.display = 'none';
        this.treeOpened = false;
        this.listOpened = false;
        this.applyOpened =false;
    } else if (id == 9) {
      if (document.getElementById('treeView'))
        document.getElementById('treeView').style.display = 'none';
      if (document.getElementById('treeList'))
        document.getElementById('treeList').style.display = 'none';
      if (document.getElementById('pointView'))
        document.getElementById('pointView').style.display = 'none';
        this.treeOpened = false;
        this.pointOpened = false;
        this.listOpened = false;
    } else {
      if (document.getElementById('treeView'))
        document.getElementById('treeView').style.display = 'none';
      if (document.getElementById('treeList'))
        document.getElementById('treeList').style.display = 'none';
      if (document.getElementById('pointView'))
        document.getElementById('pointView').style.display = 'none';
      if (document.getElementById('applyView'))
        document.getElementById('applyView').style.display = 'none';
        this.treeOpened = false;
        this.pointOpened = false;
        this.listOpened = false;
        this.applyOpened = false;
    }
  }
  checkMenu() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(document.querySelector('aside'), 'transform');
      document.getElementById('sidebar-overlay').style.display = 'none';
      this.data.sidebarMenuOpened = false;
    }
    if (document.getElementById('treeView'))
    document.getElementById('treeView').style.display = 'none';
    if (document.getElementById('pointView'))
    document.getElementById('pointView').style.display = 'none';
    if (document.getElementById('treeList'))
    document.getElementById('treeList').style.display = 'none';
    if (document.getElementById('applyView'))
      document.getElementById('applyView').style.display = 'none';
    this.treeOpened = false;
    this.pointOpened = false;
    this.listOpened = false;
    this.applyOpened = false;
  }

  toggleMenuSidebar() {
    if (this.sidebarMenuOpened) {
      this.renderer.addClass(document.querySelector('aside'), 'collapse-width');
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.sidebarMenuOpened = false;
      document.getElementById('sideMenu1').classList.remove('arrow');
      document.getElementById('sideMenu1').classList.add('arrow1');
      document.getElementById('dropdownlist1').classList.add('dropdownlist1');
      document.getElementById('dropdownlist2').classList.add('dropdownlist1');
      document.getElementById('dropdownlist3').classList.add('dropdownlist1');
      document.getElementById('dropdownlist4').classList.add('dropdownlist1');
      // document.getElementById('dropdownlist5').classList.add('dropdownlist1');
      // document.getElementById('dropdownlist6').classList.add('dropdownlist1');
      // document.getElementById('dropdownlist7').classList.add('dropdownlist1');
      document.getElementById('dropdownlist8').classList.add('dropdownlist1');
    } else {
      this.renderer.removeClass(
        document.querySelector('aside'),
        'collapse-width'
      );
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.sidebarMenuOpened = true;
      document.getElementById('sideMenu1').classList.add('arrow');
      document.getElementById('sideMenu1').classList.remove('arrow1');
      document.getElementById('dropdownlist1').classList.remove('dropdownlist1');
      document.getElementById('dropdownlist2').classList.remove('dropdownlist1');
      document.getElementById('dropdownlist3').classList.remove('dropdownlist1');
      document.getElementById('dropdownlist4').classList.remove('dropdownlist1');
      // document.getElementById('dropdownlist5').classList.remove('dropdownlist1');
      // document.getElementById('dropdownlist6').classList.remove('dropdownlist1');
      // document.getElementById('dropdownlist7').classList.remove('dropdownlist1');
      document.getElementById('dropdownlist8').classList.remove('dropdownlist1');
    }
  }

  logout() {
    this.spinner.show();
    this.appService.logout();
    this.spinner.hide();
  }
}
