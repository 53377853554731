<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'CODE_LIST.TITLE' | translate }}
  </h3>
  <div class="card-body search-form-top">
    <!-- Input -->
    <div class="row">
      <div class="input-group datetime-cls">
        <input
          type="text"
          [(ngModel)]="order_date_month"
          [dpDayPicker]="config"
          placeholder="申請月"
          mode="month"
          (onGoToCurrent)="GoToCurrent(Today)"
          (ngModelChange)="onDateChange($event)"
        />
        <img id="month" class="icon date-icon" src="assets/icons/date.svg"/>
        <img id="monthclear" style="display: none;" class="icon clear-icon" src="assets/icons/x.svg" (click)="clearDate()"/>
      </div>
      <div class="input-group code">
        <input
          type="text"
          class="form-control"
          id="Code"
          name="Code"
          [(ngModel)]="order_id"
          maxlength="18"
          placeholder="{{
            'CODE_LIST.TH_APPLY_FOR_ID' | translate
          }}"
        />
      </div> 
      <div class="input-group acquired-company">
        <select class="form-control" [(ngModel)]="order_type" [ngClass]="{'placeholder': order_type == ''}">
          <option value="" disabled selected hidden>申請分類</option>
          <option value=""></option>
          <option *ngFor="let i of order_types" value="{{i.number}}">
            {{i.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row row1">
      <div class="input-group order-staff">
        <input
          type="text"
          class="form-control"
          id="order_staff"
          [(ngModel)]="order_staff"
          maxlength="30"
          placeholder="{{
            'CODE_LIST.PURCHASER_PLACEHOLDER' | translate
          }}"
        />
        <img *ngIf="!order_staff" class="icon" src="assets/icons/book.svg" />
      </div>
      <div class="input-group company-name">
        <input
          type="text"
          class="form-control"
          id="company_name"
          [(ngModel)]="company_name"
          maxlength="30"
          placeholder="{{
            'CODE_LIST.COMPANY_NAME_PLACEHOLDER' | translate
          }}"
        />
        <img *ngIf="!company_name" class="icon" src="assets/icons/book.svg" />
      </div>
    </div>
    <div class="row row1">
      <div class="input-group date1">
        <ngx-datepicker class="datepick" [(ngModel)]="deposit_date_from" [options]="optionsFrom" (click)="Calendar(1)" #date1>
        </ngx-datepicker>
        <img id="calendar1" class="icon" src="assets/icons/date.svg"/>
        <img id="clear1" style="display: none;" class="icon clear-icon" src="assets/icons/x.svg" (click)="resetDate(1)"/>
      </div>
      <div class="interval">
        <h1 class="ftext">〜</h1>
      </div>
      <div class="input-group date">
        <ngx-datepicker class="datepick" [(ngModel)]="deposit_date_to" [options]="optionsTo" (click)="Calendar(2)" #date2>
        </ngx-datepicker>
        <img id="calendar2" class="icon date-icon" src="assets/icons/date.svg"/>
        <img id="clear2" style="display: none;" class="icon clear-icon" src="assets/icons/x.svg" (click)="resetDate(2)"/>
      </div>
    </div>
    <div class="all-check">
      <!-- Check -->
      <div class="row check-desc checkbox-top">
        <p class="card-title">{{ 'CODE_LIST.CODE_ISSUANCE_STATUS' | translate }}</p>
        <p class="card-title">{{ 'CODE_LIST.PAYMENT_STATUS' | translate }}</p>
        <!-- <p class="card-title">{{ 'CODE_LIST.ESTIMATE' | translate }}</p>
        <p class="card-title">{{ 'CODE_LIST.INVOICE' | translate }}</p> -->
        <p class="card-title">{{ 'CODE_LIST.CANCEL_APPLICATION' | translate }}</p>
      </div>
      <div class="row">
        <!-- Check 1 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_coupon_status0"
                [(ngModel)]="order_coupon_status0"
              />
              <label for="order_coupon_status0" class="custom-control-label ftext">{{
                'CODE_LIST.NOT_ISSUED' | translate
              }}</label>
            </div>
          </div>
        </div>
        <!-- Check 1 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_payment_status0"
                [(ngModel)]="order_payment_status0"
              />
              <label for="order_payment_status0" class="custom-control-label ftext">{{
                'CODE_LIST.NOT_PAID' | translate
              }}</label>
            </div>
          </div>
        </div>
        <!-- Check 2 -->
        <!-- <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_quotation0"
                [(ngModel)]="order_quotation0"
              />
              <label for="order_quotation0" class="custom-control-label ftext">{{
                'CODE_LIST.NOT_ISSUED' | translate
              }}</label>
            </div>
          </div>
        </div> -->
        <!-- Check 3 -->
        <!-- <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_invoice0"
                [(ngModel)]="order_invoice0"
              />
              <label for="order_invoice0" class="custom-control-label ftext">{{
                'CODE_LIST.NOT_ISSUED' | translate
              }}</label>
            </div>
          </div>
        </div> -->
        <!-- Check 4 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_cancel_flg1"
                [(ngModel)]="order_cancel_flg1"
              />
              <label for="order_cancel_flg1" class="custom-control-label ftext">{{
                'CODE_LIST.CANCELLED' | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Check 2 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_coupon_status1"
                [(ngModel)]="order_coupon_status1"
              />
              <label for="order_coupon_status1" class="custom-control-label ftext">{{
                'CODE_LIST.ISSUED' | translate
              }}</label>
            </div>
          </div>
        </div>
        <!-- Check 1 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_payment_status1"
                [(ngModel)]="order_payment_status1"
              />
              <label for="order_payment_status1" class="custom-control-label ftext">{{
                'CODE_LIST.COMPLETED' | translate
              }}</label>
            </div>
          </div>
        </div>
        <!-- Check 2 -->
        <!-- <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_quotation1"
                [(ngModel)]="order_quotation1"
              />
              <label for="order_quotation1" class="custom-control-label ftext">{{
                'CODE_LIST.ISSUED' | translate
              }}</label>
            </div>
          </div>
        </div> -->
        <!-- Check 3 -->
        <!-- <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="order_invoice1"
                [(ngModel)]="order_invoice1"
              />
              <label for="order_invoice1" class="custom-control-label ftext">{{
                'CODE_LIST.ISSUED' | translate
              }}</label>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="btn-search-top">
      <a target="_blank" class="btn-search" (click)="Search()"
        >{{ 'CODE_LIST.SEARCH_BUTTON' | translate }}
        <img
          class="search-icon"
          src="assets/icons/srch.svg"
        />
      </a>
    </div>
    <!-- Limit 10000 text -->
    <label class="limit-500" id="limit10000">
      {{'CODE_LIST.SEARCH_LIMIT_10000' | translate}}
    </label>
    <!-- Radio -->
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">{{
              'COMMON.CASE_100' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width">
          <table class="table table-bordered" style="min-width: 800px;">
            <thead>
              <tr class="text-center">
                <th class="ftext">
                  {{ 'CODE_LIST.TH_APPLICATION_DATE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_APPLY_FOR_ID' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_APPLICATION_CATEGORY' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_PURCHASER' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_PURCHASE_QUANTITY' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_AMOUNT_CLAIMED_EXCLUDING_TAX' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_PAYMENT_STATUS' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_DEPOSIT_DATE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_COMPANY_NAME' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_CODE_ISSUE_STATUS' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_CODE' | translate }}
                </th>
                <!-- <th class="ftext">
                  {{ 'CODE_LIST.TH_QUOTE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_INVOICE' | translate }}
                </th> -->
                <th class="ftext">
                  {{ 'CODE_LIST.TH_DETAILS' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of items" class="text-center" [ngClass]="{'row-grey': list.cancel_flg == '1'}">
                <td class="tbtext">{{ list.order_date }}</td>
                <td class="tbtext">{{ list.order_id }}</td>
                <td class="tbtext">{{ getOrderTypeName(list.order_type) }}</td>
                <td class="tbtext">{{ list.order_staff }}</td>
                <td class="tbtext tbnumber">{{ parse(list.ticket_info.ticket_total) }}</td>
                <td class="tbtext tbnumber">{{ parse(list.ticket_info.bill_tax_free) }}</td>
                <td class="tbtext">{{ getPaymentStatusName(list.payment_status) }}</td>
                <td class="tbtext">{{ list.deposit_date }}</td>
                <td class="tbtext tdataleft">{{ list.company_name }}</td>
                <td class="tbtext">{{ getCouponflgName(list.coupon_flg) }}</td>
                <td>
                  <a (click)="codeList(list)" [ngClass]="{'btn-tb5': list.coupon_flg != '0', 'btn-tb5-disable': list.coupon_flg == '0'}">
                    {{ 'CODE_LIST.LIST'| translate}}
                  </a>
                </td>
                <!-- <td>
                  <a (click)="pdf_download(list.uuid)" [ngClass]="{'btn-tb5': list.column11, 'btn-tb5-disable': !list.column11}">{{
                      'COMMON.DISPLAY_PDF_BUTTON' | translate }}
                  </a>
                </td>
                <td>
                  <a (click)="pdf_download(list.uuid)" [ngClass]="{'btn-tb5': list.column12, 'btn-tb5-disable': !list.column12}">{{
                      'COMMON.DISPLAY_PDF_BUTTON' | translate }}
                  </a>
                </td> -->
                <td>
                  <a (click)="details(list.order_id)" class="btn-tb5">{{
                      'CODE_LIST.TH_DETAILS' | translate }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>          
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;</span
          >
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{
              disabled: currentIndex == pageNumber || pageNumber == 0
            }"
            (click)="nextPage()"
            class="next"
            >&raquo;</span
          >
        </div>
        <div class="text-center" style="margin-top: 16px;max-width: 1500px;">
          <div class="col-12">
            <div class="display">
              <a (click)="csvOutput()" class="btn-search csv-font">
                {{'COMMON.CSV' | translate}}
              </a>
              <i class="fa fa-print"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>