import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-salecompany-list',
  templateUrl: './salecompany-list.component.html',
  styleUrls: ['./salecompany-list.component.scss'],
})
export class SaleCompanyListComponent implements OnInit {
  public csv1;
  public CompanyName = '';
  public Telephone = '';
  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-salecompany-list';
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        var pages = res.data;
        const page = pages.find(page => page.pagename === 'wf-salecompany-list');
        var bool = page ? page.auth === "F" : false;
        if (!bool) {
          this.router.navigate(['/']);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.Search();
    this.widthChanger('main-header', 360);
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  
  Search() {
    this.spinner.show();
    this.rest.Get_SaleCompany(this.data.uuid, this.CompanyName, this.Telephone).subscribe(
      (res) => {
        this.filteredItems = res.Companies;
        if (res.code == 'W000001') {
          document.getElementById('limit10000').style.display = 'block';
        } else {
          document.getElementById('limit10000').style.display = 'none';
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }

  details(uuid) {
    this.router.navigate(['/wf-salecompany-detail', { uuid }]);
  }
  
  csvOutput(){
    const body = {
      resist_date : "登録日",
      company_name : "会社名",
      business_type: "業種・業態",
      tel : "電話番号",
      mail_address : "メールアドレス"
    }
    var csvItems = [];
    csvItems = this.filteredItems.map((_arrayElement) => Object.assign({}, _arrayElement));
    for(let i = 0; i < csvItems.length; i++){
      csvItems[i].business_type = this.getBusinessTypeLabel(csvItems[i].business_type);
    }
    for(let i = 0; i < csvItems.length; i++){
      if(csvItems[i].judge_state == '90' && csvItems[i].contract_end_date != ''){
        csvItems[i].judge_state = '解約済'
      }else if(csvItems[i].judge_state == '90' && csvItems[i].contract_end_date == ''){
        csvItems[i].judge_state = '契約中'
      }else if(csvItems[i].judge_state == '02'){
        csvItems[i].judge_state = '審査中'
      }else if(csvItems[i].judge_state == '03'){
        csvItems[i].judge_state = '再審査可能NG'
      }else if(csvItems[i].judge_state == '05'){
        csvItems[i].judge_state = '再審査中'
      }else if(csvItems[i].judge_state == '01' || csvItems[i].judge_state == '04' || csvItems[i].judge_state == '99'){
        csvItems[i].judge_state = ''
      }
    }
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(body);
    const headerValue = Object.values(body);
    this.csv1= csvItems.map(row => header.map(fieldName =>
    JSON.stringify(row[fieldName], replacer)).join(','));
    this.csv1.unshift(headerValue.join(','));
    this.csv1 = this.csv1.join('\r\n');

    // Create link and download
    var download_name;
    download_name = '販売企業一覧_' + this.datepipe.transform(new Date(), 'yyyyMMddHHmmss');
    var link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(this.csv1));
    link.setAttribute('download', download_name + '.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  formatBusinessType(businessType: number): string {
    return businessType < 10 ? `0${businessType}` : `${businessType}`;
  }

  getBusinessTypeLabel(businessType: string): string {
    const businessTypeMap: { [key: string]: string } = {
      '01': '農業・林業',
      '02': '漁業',
      '03': '鉱業，採石業，砂利，採取業',
      '04': '建設業',
      '05': '製造業',
      '06': '電気・ガス・熱供給・水道業',
      '07': '情報通信業',
      '08': '運輸業，郵便業',
      '09': '卸売業，小売業',
      '10': '金融業，保険業',
      '11': '不動産業，物品賃貸業',
      '12': '学術研究，専門・技術サービス業',
      '13': '宿泊業，飲食サービス業',
      '14': '生活関連サービス業，娯楽業',
      '15': '教育，学習支援業',
      '16': '医療，福祉',
      '17': '複合サービス事業',
      '18': 'サービス業',
      '19': '公務',
      '20': '分類不能の産業',
    };

    return businessTypeMap[this.formatBusinessType(parseInt(businessType))] || '';
  }

  Regist() {
    this.router.navigate(['/wf-salecompany-regist']);
  }
}