import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-order-apply',
  templateUrl: './order-apply.component.html',
  styleUrls: ['./order-apply.component.scss'],
})

export class OrderApplyComponent implements OnInit {
  public CompanyName = '';
  public path_type = 'CORP';
  public outside_sale_info = '';
  public Companies = [];
  public company_uuid = '';
  public company_name = '';
  public free_flg = false;
  public order_type = '1';
  public OrderList: any = [];

  public Products = [];
  public ProductsConfirm = [];
  public totalAmount = 0;
  public totalPurchase = 0;
  public orderList001:any = [];
  public SubTotals = [];
  public totalPurchases = [];
  

  public total_ticket_num = 0;
  public total_bill_tax = 0;
  public total_bill_tax_free = 0;

  public applicable = '対象外';

  constructor(
    private router: Router, 
    public data: DataProvider,
    public rest: CallapiService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-order-apply';
    this.spinner.show();
    this.rest.Get_Product(this.data.uuid, this.path_type, this.outside_sale_info).subscribe(
      (res) => {
        console_log(res);
        this.Products = res.data.Products;
        for (var i = 0; i < this.Products.length; i++) {
          this.SubTotals.push(0);
          this.totalPurchases.push(0);
        }
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        var pages = res.data;
        const page = pages.find(page => page.pagename === 'wf-order-apply');
        var bool = page ? page.auth === "F" : false;
        if (!bool) {
          this.router.navigate(['/']);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.widthChanger('main-header', 942);
  }

  Search() {
    if (this.CompanyName != '') {
      this.spinner.show();
      this.rest.Get_CodeSaleCompany(this.data.uuid, this.CompanyName).subscribe(
        (res) => {
          console_log(res);
          this.Companies = res.Companies;
          this.spinner.hide();
          if (this.Companies.length > 0) {
            this.company_uuid = res.Companies[0].company_uuid;
            this.company_name = res.Companies[0].company_name;
          } else {
            this.company_uuid = '';
            this.company_name = '';
          }
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
        }
      );
    }
  }

  getQuantityName(number: string){
    return number == '0' ? '枚' : 'セット';
  }

  getQuantityLimitArr(quantity_limit: any){
    const quantity_limit_array = Array.from({ length: quantity_limit + 1 }, (_, i) => i);
    return  quantity_limit_array;
  }

  onInput(event: any, index: number, item: any) {
    const inputValueOrig = event.target.value;
    if (!/^\d+$/.test(inputValueOrig)) {
      event.target.value = inputValueOrig.replace(/\D/g, '');
    }
    const inputValue = event.target.value;
    this.Products[index].value = inputValue;

    if (item.set_flg == '1') {
      // set
      this.SubTotals[index] = Number(inputValue) * item.set_num * item.unit_price_tax;
      this.totalPurchases[index] = Number(inputValue) * item.set_num;
    }
    if (item.set_flg == '0') {
      // single
      this.SubTotals[index] = item.unit_price_tax * Number(inputValue);
      this.totalPurchases[index] = Number(inputValue);
    }
    const order = {
      index: index,
      product_id: item.product_id,
      num: Number(inputValue)
    };
    this.orderList001.push(order);
    this.orderList001 = this.getDistinctProducts(this.orderList001).filter(product => product.num !== 0);
    this.orderList001 = this.orderList001.sort((a, b) => a.index - b.index);
    console_log(this.orderList001);
    this.totalAmountSum();
    this.totalPurchaseSum();
  }

  indexKeyRemove(data){
    return data.map(({ index, ...rest }) => rest)
  }

  onChangeFree() {
    console_log(this.free_flg);
    this.applicable = this.free_flg ? '対象' : '対象外';
  }

  billamount() {
    this.spinner.show();
    console_log(this.orderList001);
    this.orderList001 = this.getDistinctProducts(this.orderList001).filter(product => product.num !== 0);
    this.orderList001 = this.indexKeyRemove(this.orderList001);
    this.rest.Get_Ticket_Payment(
      this.data.uuid,
      this.free_flg ? '1' : '0',
      this.orderList001
    ).subscribe(
      (res) => {
        this.spinner.hide();
        this.total_ticket_num = res.data.total_ticket_num;
        this.total_bill_tax = res.data.total_bill_tax;
        this.total_bill_tax_free = res.data.total_bill_tax_free;
        this.OrderList = res.data.OrderList;
        this.ProductsConfirm = [];
        for (var i = 0; i < this.OrderList.length; i++) {
          for (var j = 0; j < this.Products.length; j++) {
            if (this.OrderList[i].product_id == this.Products[j].product_id) {
              const data = {
                product_name: this.Products[j].product_name,
                value: this.Products[j].value,
                set_flg: this.Products[j].set_flg,
                product_id: this.OrderList[i].product_id,
                ticket_num: this.OrderList[i].ticket_num,
                bill_unit_price_tax: this.OrderList[i].bill_unit_price_tax,
                bill_tax: this.OrderList[i].bill_tax,
                bill_tax_free: this.OrderList[i].bill_tax_free
              }
              this.ProductsConfirm.push(data);
            }
          }
        }
        document.getElementById('confirm').style.display = 'block';
        document.getElementById('edit').style.display = 'none';
        console_log(res);
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }

  getDistinctProducts(products) {
    const productMap = new Map<string, any>();
  
    products.forEach(product => {
      productMap.set(product.product_id, product);
    });
  
    return Array.from(productMap.values());
  }

  totalPurchaseSum () {
    this.totalPurchase = 0;
    for (const number of this.totalPurchases) {
      this.totalPurchase += number;
    }
  }

  totalAmountSum() {
    this.totalAmount = 0;
    for (const number of this.SubTotals) {
      this.totalAmount += number;
    }
  }

  onChangeCompany(c) {
    this.company_name = c.company_name;
    this.company_uuid = c.company_uuid;
  }

  dataValidation() {
    let err = false;
    let errTxt = '';
    if (this.orderList001.length == 0) {
      err = true;
      this.translate
        .get('CODE_APPLY.ERROR_ORDER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.company_name == null || this.company_name == '') {
      err = true;
      this.translate
        .get('CODE_APPLY.ERROR_COMPANY_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }

  Next() {
    if (this.dataValidation()) {
      document.getElementById('error').style.display = 'none';
      this.billamount();
    }
  }

  back() {
    document.getElementById('confirm').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
  }

  apply() {
    this.spinner.show();
    this.rest.Create_CouponOrder(
      this.data.uuid,
      this.company_uuid,
      this.order_type,
      this.free_flg ? '1' : '0',
      this.OrderList
    ).subscribe(
      (res) => {
        console_log(res);
        this.spinner.hide();
        this.router.navigate(['/wf-order-apply-comp']);
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }

  parse(e) {
    return '¥' + parseFloat(e).toLocaleString('en');
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
}
