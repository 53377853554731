<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'SALECOMPANY_LIST.TITLE' | translate }}</h3>
  <div class="text-center">
    <div class="text-center finish">
      {{ 'SALECOMPANY_LIST.COMP_TEXT' | translate }}
    </div>
    <div class="text-center">
      <div class="col-12">
        <div class="display finish2">
          <a (click)="back()" class="btn-grey">
            {{ 'SALECOMPANY_LIST.RETURN_LIST' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>     
</div>