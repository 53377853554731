import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-order-apply',
  templateUrl: './order-apply.component.html',
  styleUrls: ['./order-apply.component.scss'],
})
export class OrderApplyComponent implements OnInit {
  public CompanyName = '';
  public path_type = 'CORP';
  public outside_sale_info = '';
  public Companies = [];
  public company_uuid = '';
  public company_name = '';
  public free_flg = false;
  public order_type = '1';
  public OrderList: any = [];
  public OrderListSingle: any = [];
  public OrderListSet: any = [];

  public Products = [];
  public singleItem = [];
  public setItem = [];
  public singleItemConfirm = [];
  public setItemConfirm = [];
  public singleSubTotal = 0;
  public singleSubTotals = [];
  public setSubTotal = 0;
  public setSubTotals = [];
  public totalAmount = 0;
  public totalPurchase = 0;
  public totalPurchases = [];
  public totalPurchasesSingles = [];
  public orderList001:any = [];

  public total_ticket_num = 0;
  public total_bill_tax = 0;
  public total_bill_tax_free = 0;

  constructor(
    private router: Router, 
    public data: DataProvider,
    public rest: CallapiService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-order-apply';
    this.spinner.show();
    this.rest.Get_Product(this.data.uuid, this.path_type, this.outside_sale_info).subscribe(
      (res) => {
        console_log(res);
        this.Products = res.data.Products;
        for (var i = 0; i < this.Products.length; i++) {
          if (this.Products[i].set_flg == '1') {
            this.setItem.push(this.Products[i]);
            this.setSubTotals.push(0);
            this.totalPurchasesSingles.push(0);
          }
          if (this.Products[i].set_flg == '0') {
            this.singleItem.push(this.Products[i]);
            this.singleSubTotals.push(0);
            this.totalPurchases.push(0);
          }
        }
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
      }
    );
    this.widthChanger('main-header', 942);
  }

  Search() {
    if (this.CompanyName != '') {
      this.spinner.show();
      this.rest.Get_CodeSaleCompany(this.data.uuid, this.CompanyName).subscribe(
        (res) => {
          console_log(res);
          this.Companies = res.Companies;
          this.spinner.hide();
          if (this.Companies.length > 0) {
            this.company_uuid = res.Companies[0].company_uuid;
            this.company_name = res.Companies[0].company_name;
          }
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
        }
      );
    }
  }

  onChangeSingle(event: Event, index: number, single) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.singleItem[index].value = inputValue;
    var bill_tax_free = single.unit_price * Number(inputValue)
    this.singleSubTotals[index] = bill_tax_free;
    const order = {
      product_id: single.product_id,
      num: Number(inputValue)
    };
    this.orderList001.push(order);
    this.totalPurchasesSingles[index] = Number(inputValue);
    console_log(this.singleSubTotals);
    this.singleSubTotal = 0;
    for (const number of this.singleSubTotals) {
      this.singleSubTotal += number;
    }
    this.totalAmountSum();
    this.totalPurchaseSum();
  }

  onChangeSet(event: Event, index: number, set) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.setItem[index].value = inputValue;
    var bill_tax_free = Number(inputValue) * set.set_num * set.unit_price;
    var ticket_num = Number(inputValue) * set.set_num;
    this.setSubTotals[index] = bill_tax_free;
    const order = {
      product_id: set.product_id,
      num: Number(inputValue)
    };
    this.orderList001.push(order);
    this.totalPurchases[index] = ticket_num;
    console_log(this.setSubTotals);
    this.setSubTotal = 0;
    for (const number of this.setSubTotals) {
      this.setSubTotal += number;
    }
    this.totalAmountSum();
    this.totalPurchaseSum();
  }

  onChangeFree() {
    console_log(this.free_flg);
  }

  billamount() {
    this.spinner.show();
    console_log(this.orderList001);
    this.orderList001 = this.getDistinctProducts(this.orderList001).filter(product => product.num !== 0);
    this.rest.Get_Ticket_Payment(
      this.data.uuid,
      this.free_flg ? '1' : '0',
      this.orderList001
    ).subscribe(
      (res) => {
        this.spinner.hide();
        this.total_ticket_num = res.data.total_ticket_num;
        this.total_bill_tax = res.data.total_bill_tax;
        this.total_bill_tax_free = res.data.total_bill_tax_free;
        this.OrderList = res.data.OrderList;
        this.singleItemConfirm = [];
        this.setItemConfirm = [];
        for (var i = 0; i < this.OrderList.length; i++) {
          for (var j = 0; j < this.singleItem.length; j++) {
            if (this.OrderList[i].product_id == this.singleItem[j].product_id) {
              const data = {
                product_name: this.singleItem[j].product_name,
                value: this.singleItem[j].value,
                product_id: this.OrderList[i].product_id,
                ticket_num: this.OrderList[i].ticket_num,
                bill_unit_price_tax: this.OrderList[i].bill_unit_price_tax,
                bill_tax: this.OrderList[i].bill_tax,
                bill_tax_free: this.OrderList[i].bill_tax_free
              }
              this.singleItemConfirm.push(data);
            }
          }
          for (var k = 0; k < this.setItem.length; k++) {
            if (this.OrderList[i].product_id == this.setItem[k].product_id) {
              const data = {
                product_name: this.setItem[j].product_name,
                value: this.setItem[j].value,
                product_id: this.OrderList[i].product_id,
                ticket_num: this.OrderList[i].ticket_num,
                bill_unit_price_tax: this.OrderList[i].bill_unit_price_tax,
                bill_tax: this.OrderList[i].bill_tax,
                bill_tax_free: this.OrderList[i].bill_tax_free
              }
              this.setItemConfirm.push(data);
            }
          }
        }
        if (this.dataValidation()) {
          document.getElementById('confirm').style.display = 'block';
          document.getElementById('edit').style.display = 'none';
        }
        console_log(res);
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }

  getDistinctProducts(products) {
    const productMap = new Map<string, any>();
  
    products.forEach(product => {
      productMap.set(product.product_id, product);
    });
  
    return Array.from(productMap.values());
  }
  
  totalPurchaseSum () {
    this.totalPurchase = 0;
    for (const number of this.totalPurchasesSingles) {
      this.totalPurchase += number;
    }
    for (const number of this.totalPurchases) {
      this.totalPurchase += number;
    }
  }
  totalAmountSum() {
    this.totalAmount = 0;
    this.totalAmount = this.singleSubTotal + this.setSubTotal;
  }

  onChangeCompany(c) {
    this.company_name = c;
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    if (this.OrderList.length == 0) {
      err = true;
      this.translate
        .get('CODE_APPLY.ERROR_ORDER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.company_name == null || this.company_name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  Next() {
    this.billamount();
  }

  back() {
    document.getElementById('confirm').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
  }

  apply() {
    this.spinner.show();
    this.rest.Create_CouponOrder(
      this.data.uuid,
      this.company_uuid,
      this.order_type,
      this.free_flg ? '1' : '0',
      this.OrderList
    ).subscribe(
      (res) => {
        console_log(res);
        this.spinner.hide();
        this.router.navigate(['/wf-order-apply-comp']);
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }

  parse(e) {
    return '¥' + parseFloat(e).toLocaleString('en');
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
}
