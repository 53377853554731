<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'CODE_APPLY.TITLE' | translate }}</h3>
  <div class="text-center">
    <div class="finish text-center">
      {{ 'CODE_APPLY.COMPLETE_TEXT_1' | translate }}<br>
      {{ 'CODE_APPLY.COMPLETE_TEXT_2' | translate }}<br>
      {{ 'CODE_APPLY.COMPLETE_TEXT_3' | translate }}
    </div>
    <div class="finish2 text-center">
      <a (click)="backList()" class="btn-link">{{ 'CODE_APPLY.APPLY_LIST' | translate }}</a>
    </div>
    <div class="text-center">
      <div class="col-12">
        <div class="display finish3">
          <a (click)="back()" class="btn-grey">
            {{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>     
</div>