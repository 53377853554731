import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
import { IDatePickerConfig } from 'ng2-date-picker';
import moment from 'moment';

@Component({
  selector: 'app-code-list',
  templateUrl: './code-list.component.html',
  styleUrls: ['./code-list.component.scss'],
})

export class CodeListComponent implements OnInit {
  public order_id = '';
  public corporation_name = '';
  public coupon_create_date_month = '';
  public coupon_code_1 = '';
  public coupon_code_2 = '';
  public coupon_code_3 = '';
  public order_staff = '';
  public coupon_unused = false;
  public coupon_use = false;
  public coupon_used = false;
  public coupon_expired = false;
  public coupon_revoked = false;
  public coupon_refund = false;
  
  public Today;

  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-code-list';
    this.order_id = this.route.snapshot.paramMap.get('order_id');
    if (!this.order_id) {
      this.order_id = ''; 
    }
    this.Today = new Date();
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        var pages = res.data;
        const page = pages.find(page => page.pagename === 'wf-code-list');
        var bool = page ? page.auth === "F" : false;
        if (!bool) {
          this.router.navigate(['/']);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.Search();
    this.widthChanger('main-header', 360);
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  Search() {
    this.spinner.show();
    this.rest.Get_CouponCode(
      this.data.uuid,
      this.data.scope,
      this.order_id,
      this.corporation_name,
      this.coupon_create_date_month,
      this.coupon_code_1,
      this.coupon_code_2,
      this.coupon_code_3,
      this.order_staff,
      this.coupon_unused ? '1' : '',
      this.coupon_use ? '1' : '',
      this.coupon_used ? '1' : '',
      this.coupon_expired ? '1' : '',
      this.coupon_revoked ? '1' : '',
      this.coupon_refund ? '1' : ''
    ).subscribe(
      (res)=>{
        console_log(res);
        this.filteredItems = res.Coupons;
        if (res.code == 'W000001') {
          document.getElementById('limit10000').style.display = 'block';
        } else {
          document.getElementById('limit10000').style.display = 'none';
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.spinner.hide();
        this.refreshItems();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }

  ticketList(list) {
    if (list.ticket_flg == '1') {
      this.router.navigate(['/wf-ticket-list', {coupon_code: list.coupon_code}]);
    }
  }

  pdf_download(uuid) {
  }

  csvOutput() {
    this.spinner.show();
    this.rest.Create_CouponsCsvFile(
      this.data.uuid,
      this.data.scope,
      this.order_id,
      this.corporation_name,
      this.coupon_create_date_month,
      this.coupon_code_1,
      this.coupon_code_2,
      this.coupon_code_3,
      this.order_staff,
      this.coupon_unused ? '1' : '',
      this.coupon_use ? '1' : '',
      this.coupon_used ? '1' : '',
      this.coupon_expired ? '1' : '',
      this.coupon_revoked ? '1' : '',
    ).subscribe(
      (res) => {
        console_log(res);
        const link = document.createElement('a');
        link.href = res.data.s3url;
        link.click();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }

  parse(e) {
    return parseFloat(e).toLocaleString('en');
  }
  config: IDatePickerConfig = {
    format: 'yyyy-MM',
    yearFormat: 'yyyy' + '年',
    disableKeypress: true,
    onOpenDelay: 0,
    closeOnSelect: true,
    monthFormat: 'MM',
    monthBtnFormat: 'MM' + '月',
  }
  GoToCurrent(event) {
    event = moment().subtract(0, 'days');
    this.coupon_create_date_month = event;
  }

  onDateChange(event: any): void {
    console_log(event);
    this.coupon_create_date_month = event;
    if(this.coupon_create_date_month != ''){
      document.getElementById('clear1').style.display = 'block';
      document.getElementById('calendar1').style.display = 'none';
    }else{
      document.getElementById('clear1').style.display = 'none';
      document.getElementById('calendar1').style.display = 'block';
    }
  }

  clearDate() {
    this.coupon_create_date_month = '';
    if(this.coupon_create_date_month == ''){
      document.getElementById('clear1').style.display = 'none';
      document.getElementById('calendar1').style.display = 'block';
    }
  }
}