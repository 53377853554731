<aside
  #mainSidebar
  class="main-sidebar sidebar-dark-primary elevation-4"
  id="main-sidebar"
>
  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="true"
      >
        <li class="nav-item d-none d-sm-none d-md-none d-lg-block d-xl-block">
          <a
            id="sideMenu1"
            class="arrow"
            (click)="toggleMenuSidebar()"
            role="button"
            style="border-top: none;"
          >
            <i class="fas fa-bars"></i>
          </a>
        </li>
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <!-- *ngIf="data.menuHome == true" -->
        <!-- ホーム -->
        <li class="nav-item" *ngIf="data.menuHome">
          <a
            *ngIf="data.menuPageTitle == 'wf-home'"
            [routerLink]="['/']"
            (click)="checkMenu()"
            class="nav-link active"
          >
            <i class="nav-icon icon-home"></i>
            <p class="side-text">{{ 'SIDENAV.MENU_1_HOME' | translate }}</p>
          </a>
          <a
            *ngIf="data.menuPageTitle != 'wf-home'"
            [routerLink]="['/']"
            (click)="checkMenu()"
            class="nav-link"
          >
            <i class="nav-icon icon-home"></i>
            <p class="side-text">{{ 'SIDENAV.MENU_1_HOME' | translate }}</p>
          </a>
        </li>
        <!-- アカウント管理 -->
        <li class="nav-item" *ngIf="data.menuCreate || data.menuAccountList">
          <a
            id="collapsable"
            class="nav-link"
            style="cursor: pointer"
            (click)="collapseTree()"
          >
            <i class="nav-icon icon-settings"></i>
            <p class="side-text" id="collapsable-text">
              {{ 'SIDENAV.MENU_2_ACCOUNT_MANAGEMENT' | translate }}
            </p>
          </a>
          <ul
            class="nav nav-pills nav-sidebar flex-column treeview"
            style="display: none"
            id="treeView"
          >
            <li class="nav-item" *ngIf="data.menuCreate">
              <a
                *ngIf="data.menuPageTitle == 'wf-account-create'"
                [routerLink]="['/wf-account-create']"
                class="nav-link active"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist1">
                  {{ 'SIDENAV.MENU_2_1_ACCOUNT_ISSUE' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-account-create'"
                [routerLink]="['/wf-account-create']"
                class="nav-link"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist1">
                  {{ 'SIDENAV.MENU_2_1_ACCOUNT_ISSUE' | translate }}
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="data.menuAccountList">
              <a
                *ngIf="data.menuPageTitle == 'wf-account-list'"
                [routerLink]="['/wf-account-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(2)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist2">
                  {{ 'SIDENAV.MENU_2_2_ACCOUNT_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-account-list'"
                [routerLink]="['/wf-account-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(2)"
              > 
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist2">
                  {{ 'SIDENAV.MENU_2_2_ACCOUNT_LIST' | translate }}
                </p>
              </a>
            </li>
          </ul>
        </li>
        <!-- 告知文 -->
        <li class="nav-item" *ngIf="data.menuAnnouncement">
          <a
            *ngIf="data.menuPageTitle == 'wf-announcement'"
            [routerLink]="['/wf-announcement']"
            class="nav-link active"
            (click)="checkMenu()"
          >
            <i class="nav-icon icon-notif"></i>
            <p class="side-text">
              {{ 'SIDENAV.MENU_3_ANNOUNCEMENT' | translate }}
            </p>
          </a>
          <a
            *ngIf="data.menuPageTitle != 'wf-announcement'"
            [routerLink]="['/wf-announcement']"
            class="nav-link"
            (click)="checkMenu()"
          >
            <i class="nav-icon icon-notif"></i>
            <p class="side-text">
              {{ 'SIDENAV.MENU_3_ANNOUNCEMENT' | translate }}
            </p>
          </a>
        </li>
        <!-- 法人管理 -->
        <li class="nav-item" *ngIf="data.menuApplyList || data.menuContractList || data.menuDistributionList || data.menuUnpaidList">
          <a
            id="collapsable1"
            class="nav-link"
            style="cursor: pointer"
            (click)="collapseList()"
          >
            <i class="nav-icon icon-notebook"></i>
            <p class="side-text" id="collapsable1-text">
              {{ 'SIDENAV.MENU_4_CORPORATE_MANAGEMENT' | translate }}
            </p>
          </a>
          <ul
            class="nav nav-pills nav-sidebar flex-column treeview"
            style="display: none"
            id="treeList"
          >
            <!-- 1 -->
            <li class="nav-item" *ngIf="data.menuApplyList">
              <a
                *ngIf="data.menuPageTitle == 'wf-contract-apply-list'"
                [routerLink]="['/wf-contract-apply-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(3)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist3">
                  {{ 'SIDENAV.MENU_4_1_CONTRACT_APPLICATION_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-contract-apply-list'"
                [routerLink]="['/wf-contract-apply-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(3)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist3">
                  {{ 'SIDENAV.MENU_4_1_CONTRACT_APPLICATION_LIST' | translate }}
                </p>
              </a>


              
            </li>

            <!-- 2 -->
            <li class="nav-item" *ngIf="data.menuContractList">
              <a
                *ngIf="data.menuPageTitle == 'wf-contract-list'"
                [routerLink]="['/wf-contract-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(4)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDENAV.MENU_4_2_CONTRACTED_COMPANY_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-contract-list'"
                [routerLink]="['/wf-contract-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(4)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDENAV.MENU_4_2_CONTRACTED_COMPANY_LIST' | translate }}
                </p>
              </a>
            </li>
            <!-- 3 -->
            <!-- <li class="nav-item" *ngIf="data.menuDistributionList">
              <a
                *ngIf="data.menuPageTitle == 'wf-distribution-list'"
                [routerLink]="['/wf-distribution-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(5)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist5">
                  {{ 'SIDENAV.MENU_5_DISTRIBUTION_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-distribution-list'"
                [routerLink]="['/wf-distribution-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(5)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist5">
                  {{ 'SIDENAV.MENU_5_DISTRIBUTION_LIST' | translate }}
                </p>
              </a>
            </li> -->
            <!-- 4 -->
            <!-- <li class="nav-item" *ngIf="data.menuUnpaidList">
              <a
                *ngIf="data.menuPageTitle == 'wf-unpaid-list'"
                [routerLink]="[wf_unpaid_list]"
                class="nav-link active"
                (click)="checkMenuDropDownList(6)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist6">
                  {{ 'SIDENAV.MENU_6_ACCOUNTS_PAYABLE_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-unpaid-list'"
                [routerLink]="[wf_unpaid_list]"
                class="nav-link"
                (click)="checkMenuDropDownList(6)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist6">
                  {{ 'SIDENAV.MENU_6_ACCOUNTS_PAYABLE_LIST' | translate }}
                </p>
              </a>
            </li> -->
            <!-- 2 -->
            <li class="nav-item" *ngIf="data.menuRegistrantList" >
              <a
                *ngIf="data.menuPageTitle == 'wf-registrant-list'"
                [routerLink]="['/wf-registrant-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(8)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist8">
                  {{ 'SIDENAV.MENU_8_REGISTRANT_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-registrant-list'"
                [routerLink]="['/wf-registrant-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(8)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist8">
                  {{ 'SIDENAV.MENU_8_REGISTRANT_LIST' | translate }}
                </p>
              </a>
            </li>
          </ul>
        </li>
        <!-- ポイント管理 -->
        <li class="nav-item" *ngIf="data.menuPoint">
          <a
            id="collapsable_point"
            class="nav-link"
            style="cursor: pointer"
            (click)="collapsePoint()"
          >
            <i class="nav-icon icon-heart"></i>
            <p class="side-text" id="collapsable_point_text">
              {{ 'SIDENAV.MENU_7_POINT' | translate }}
            </p>
          </a>
          <ul
            class="nav nav-pills nav-sidebar flex-column treeview"
            style="display: none"
            id="pointView"
          >
            <li class="nav-item" *ngIf="data.menuPoint">
              <a
                *ngIf="data.menuPageTitle == 'wf-point'"
                [routerLink]="['/wf-point']"
                class="nav-link active"
                (click)="checkMenuDropDownList(7)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist7">
                  {{ 'SIDENAV.MENU_7_1_POINT' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-point'"
                [routerLink]="['/wf-point']"
                class="nav-link"
                (click)="checkMenuDropDownList(7)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist7">
                  {{ 'SIDENAV.MENU_7_1_POINT' | translate }}
                </p>
              </a>
            </li>
          </ul>
        </li>
        <!-- コード管理 -->
        <li class="nav-item">
          <a
            id="apply1"
            class="nav-link"
            style="cursor: pointer"
            (click)="collapseApply()"
          >
            <i class="nav-icon icon-notebook"></i>
            <p class="side-text" id="apply1-text">
              {{ 'SIDENAV.MENU_9_ORDER' | translate }}
            </p>
          </a>
          <ul
            class="nav nav-pills nav-sidebar flex-column treeview"
            style="display: none"
            id="applyView"
          >
            <!-- 1 -->
            <li class="nav-item">
              <a
                *ngIf="data.menuPageTitle == 'wf-order-apply'"
                [routerLink]="['/wf-order-apply']"
                class="nav-link active"
                (click)="checkMenuDropDownList(9)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist3">
                  {{ 'SIDENAV.MENU_9_ORDER_APPLY' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-order-apply'"
                [routerLink]="['/wf-order-apply']"
                class="nav-link"
                (click)="checkMenuDropDownList(9)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist3">
                  {{ 'SIDENAV.MENU_9_ORDER_APPLY' | translate }}
                </p>
              </a>
            </li>
            <!-- 2 -->
            <li class="nav-item">
              <a
                *ngIf="data.menuPageTitle == 'wf-order-list'"
                [routerLink]="['/wf-order-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(9)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDENAV.MENU_9_ORDER_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-order-list'"
                [routerLink]="['/wf-order-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(9)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDENAV.MENU_9_ORDER_LIST' | translate }}
                </p>
              </a>
            </li>
            <!-- 3 -->
            <li class="nav-item">
              <a
                *ngIf="data.menuPageTitle == 'wf-code-list'"
                [routerLink]="['/wf-code-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(9)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDENAV.MENU_9_CODE_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-code-list'"
                [routerLink]="['/wf-code-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(9)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDENAV.MENU_9_CODE_LIST' | translate }}
                </p>
              </a>
            </li>
            <!-- 4 -->
            <li class="nav-item">
              <a
                *ngIf="data.menuPageTitle == 'wf-salecompany-list'"
                [routerLink]="['/wf-salecompany-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(9)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDENAV.MENU_9_SALECOMPANY_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'wf-salecompany-list'"
                [routerLink]="['/wf-salecompany-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(9)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDENAV.MENU_9_SALECOMPANY_LIST' | translate }}
                </p>
              </a>
            </li>
          </ul>
        </li>
        <!-- ログアウト -->
        <li class="nav-item" style="border-bottom: 1px solid #707070;">
          <a (click)="logout()" class="nav-link" style="cursor: pointer">
            <i class="nav-icon icon-logout"></i>
            <p class="side-text">{{ 'SIDENAV.MENU_8_LOGOUT' | translate }}</p>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen]="true"><p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>></ngx-spinner>