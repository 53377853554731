import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-salecompany-detail',
  templateUrl: './salecompany-detail.component.html',
  styleUrls: ['./salecompany-detail.component.scss'],
})
export class SaleCompanyDetailComponent implements OnInit {
  public uuid;
  public Corporate_Status;
  public Corporation_Form;
  public Company_Name;
  public Company_Name_Kana;
  public Post_Code;
  public Address_1;
  public Address_2;
  public Address_3;
  public TelePhone;
  public Email;
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public business_type;
  public BusinessType = [];
  public Business_Type_Name;  
  
  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
    this.BusinessType = [
      {code: "01", name: "農業・林業"},
      {code: "02", name: "漁業"},
      {code: "03", name: "鉱業，採石業，砂利，採取業"},
      {code: "04", name: "建設業"},
      {code: "05", name: "製造業"},
      {code: "06", name: "電気・ガス・熱供給・水道業"},
      {code: "07", name: "情報通信業"},
      {code: "08", name: "運輸業，郵便業"},
      {code: "09", name: "卸売業，小売業"},
      {code: "10", name: "金融業，保険業"},
      {code: "11", name: "不動産業，物品賃貸業"},
      {code: "12", name: "学術研究，専門・技術サービス業"},
      {code: "13", name: "宿泊業，飲食サービス業"},
      {code: "14", name: "生活関連サービス業，娯楽業"},
      {code: "15", name: "教育，学習支援業"},
      {code: "16", name: "医療，福祉"},
      {code: "17", name: "複合サービス事業"},
      {code: "18", name: "サービス業"},
      {code: "19", name: "公務"},
      {code: "20", name: "分類不能の産業"},
    ];
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-salecompany-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.spinner.show()
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        var pages = res.data;
        const page = pages.find(page => page.pagename === 'wf-salecompany-detail');
        var bool = page ? page.auth === "F" : false;
        if (!bool) {
          this.router.navigate(['/']);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.rest.Get_SaleCompanyId(this.data.uuid, this.uuid).subscribe(
      (res) => {
        this.Corporate_Status = res.data.corporate_status;
        this.Corporation_Form = res.data.corporation_form;
        this.Company_Name = res.data.company_name;
        this.Company_Name_Kana = res.data.company_name_kana;
        this.Post_Code = res.data.postcode;
        this.Address_1 = res.data.address_1;
        this.Address_2 = res.data.address_2;
        this.Address_3 = res.data.address_3;
        this.TelePhone = res.data.tel;
        this.Email = res.data.mail_address;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.Business_Type_Name = this.getBusinessTypeName(res.data.business_type.length == 1 ? '0' + res.data.business_type : res.data.business_type);
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.widthChanger('main-header', 752);
  }
  getBusinessTypeName(code) {
    const business = this.BusinessType.find(b => b.code === code);
    return business ? business.name : '';
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          
          header.style.minWidth = width.toString() + 'px';
      }
  }
  back() {
    this.router.navigate(['/wf-salecompany-list']);
  }
  edit() {
    this.router.navigate(['/wf-salecompany-edit', { uuid: this.uuid }]);
  }
}
