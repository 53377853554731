<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'CODE_LIST.CODE_TITLE' | translate }}
  </h3>
  <div class="card-body search-form-top">
    <!-- Input -->
    <div class="row">
      <div class="input-group datetime-cls">
        <input
          type="text"
          [(ngModel)]="coupon_create_date_month"
          [dpDayPicker]="config"
          placeholder="発行月"
          mode="month"
          (onGoToCurrent)="GoToCurrent(Today)"
          (ngModelChange)="onDateChange($event)"
        />
        <img id="calendar1" class="icon date-icon" src="assets/icons/date.svg"/>
        <img id="clear1" style="display: none;" class="icon clear-icon" src="assets/icons/x.svg" (click)="clearDate()"/>
      </div>
      <div class="input-group company-name">
        <input 
        type="text" 
        class="form-control"
        id="order_id"
        [(ngModel)]="order_id"
        maxlength="18"
        placeholder="{{ 'CODE_LIST.TH_APPLY_FOR_ID' | translate }}"
        >
      </div>
      <div class="input-group code">
        <input
          type="text"
          class="form-control"
          id="coupon_code_1"
          name="coupon_code_1"
          [(ngModel)]="coupon_code_1"
          placeholder="{{
            'CODE_LIST.CODE_PLACEHOLDER' | translate
          }}"
          maxlength="4"
        />
      </div>
      <div class="minus">ー</div>
      <div class="input-group code">
        <input
          type="text"
          class="form-control"
          id="coupon_code_2"
          name="coupon_code_2"
          [(ngModel)]="coupon_code_2"
          placeholder="{{
            'CODE_LIST.CODE_PLACEHOLDER' | translate
          }}"
          maxlength="4"
        />
      </div>
      <div class="minus">ー</div>
      <div class="input-group code">
        <input
          type="text"
          class="form-control"
          id="coupon_code_3"
          name="coupon_code_3"
          [(ngModel)]="coupon_code_3"
          placeholder="{{
            'CODE_LIST.CODE_PLACEHOLDER' | translate
          }}"
          maxlength="4"
        />
      </div>
    </div>
    <div class="row row-code">
      <div class="input-group company-name">
        <input
          type="text"
          class="form-control"
          id="order_staff"
          [(ngModel)]="order_staff"
          maxlength="30"
          placeholder="{{
            'CODE_LIST.PURCHASER_PLACEHOLDER' | translate
          }}"
        />
        <img *ngIf="!order_staff" class="icon" src="assets/icons/book.svg" />
      </div>
      <div class="input-group company-name">
        <input
          type="text"
          class="form-control"
          id="corporation_name"
          [(ngModel)]="corporation_name"
          maxlength="30"
          placeholder="{{
            'CODE_LIST.COMPANY_NAME_PLACEHOLDER' | translate
          }}"
        />
        <img *ngIf="!corporation_name" class="icon" src="assets/icons/book.svg" />
      </div>
      
    </div>
    <div class="all-check">
      <!-- Code Status Check -->
      <div class="row checkbox-top">
        <p class="card-title">{{ 'CODE_LIST.STATUS' | translate }}</p>
      </div>
      <div class="row">
        <!-- Check 1 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="coupon_unused"
                [(ngModel)]="coupon_unused"
              />
              <label for="coupon_unused" class="custom-control-label ftext">{{
                'CODE_LIST.UNUSED' | translate
              }}</label>
            </div>
          </div>
        </div>
        <!-- Check 2 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="coupon_use"
                [(ngModel)]="coupon_use"
              />
              <label for="coupon_use" class="custom-control-label ftext">{{
                'CODE_LIST.IN_USE' | translate
              }}</label>
            </div>
          </div>
        </div>
        <!-- Check 3 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="coupon_used"
                [(ngModel)]="coupon_used"
              />
              <label for="coupon_used" class="custom-control-label ftext">{{
                'CODE_LIST.USED' | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Check 4 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="coupon_expired"
                [(ngModel)]="coupon_expired"
              />
              <label for="coupon_expired" class="custom-control-label ftext">{{
                'CODE_LIST.EXPIRED' | translate
              }}</label>
            </div>
          </div>
        </div>
        <!-- Check 5 -->
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="coupon_revoked"
                [(ngModel)]="coupon_revoked"
              />
              <label for="coupon_revoked" class="custom-control-label ftext">{{
                'CODE_LIST.EXPIRED1' | translate
              }}</label>
            </div>
          </div>
        </div>
        <!-- Check 6 -->
        <!-- <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="checkbox"
                id="coupon_refund"
                [(ngModel)]="coupon_refund"
              />
              <label for="coupon_refund" class="custom-control-label ftext">{{
                'CODE_LIST.REFUND' | translate
              }}</label>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="btn-search-top">
      <a target="_blank" class="btn-search" (click)="Search()"
        >{{ 'CODE_LIST.SEARCH_BUTTON' | translate }}
        <img
          class="search-icon"
          src="assets/icons/srch.svg"
        />
      </a>
    </div>
    <!-- Limit 10000 text -->
    <label class="limit-500" id="limit10000">
      {{'CODE_LIST.SEARCH_LIMIT_10000' | translate}}
    </label>
    <!-- Radio -->
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">{{
              'COMMON.CASE_100' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width">
          <table class="table table-bordered" style="min-width: 800px;">
            <thead>
              <tr class="text-center">
                <th class="ftext">
                  {{ 'CODE_LIST.TH_ISSUE_DATE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_APPLY_FOR_ID' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_COMPANY_NAME' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_PURCHASER' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_NUMBER_OF_COPIES_ISSUED' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_PURCHASE_PRICE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_TICKET_TYPE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.STATUS' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_CODE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_CODE_EXPIRATION_DATE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_NUMBER_OF_TICKETS_ISSUED' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CODE_LIST.TH_TICKET' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of items" class="text-center">
                <td class="tbtext">{{ list.coupon_create_date }}</td>
                <td class="tbtext">{{ list.order_id }}</td>
                <td class="tbtext tdataleft">{{ list.corporation_name }}</td>
                <td class="tbtext">{{ list.order_staff }}</td>
                <td class="tbtext tbnumber">{{ parse(list.ticket_total) }}</td>
                <td class="tbtext tbnumber">{{ parse(list.bill_tax_free) }}</td>
                <td class="tbtext">{{ list.product_name }}</td>
                <td class="tbtext">{{ list.coupon_status }}</td>
                <td class="tbtext">{{ list.coupon_code }}</td>
                <td class="tbtext">{{ list.coupon_expire_date }}</td>
                <td class="tbtext">{{ list.ticket_count }}</td>
                <td>
                  <a (click)="ticketList(list)" [ngClass]="{'btn-tb5': list.ticket_flg == '1', 'btn-tb5-disable': list.ticket_flg == '0'}">
                    {{ 'CODE_LIST.LIST'| translate}}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>          
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;</span
          >
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{
              disabled: currentIndex == pageNumber || pageNumber == 0
            }"
            (click)="nextPage()"
            class="next"
            >&raquo;</span
          >
        </div>
        <div class="text-center" style="margin-top: 16px;max-width: 1500px;">
          <div class="col-12">
            <div class="display">
              <a (click)="csvOutput()" class="btn-search btn-csv csv-font">
                {{'COMMON.CSV' | translate}}
              </a>
              <i class="fa fa-print"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>