<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'SALECOMPANY_LIST.TITLE' | translate }}
    <div class="btn-sign-up-left">
      <a target="_blank" class="btn-sign-up" (click)="Regist()">{{ 'SALECOMPANY_LIST.SIGN_UP' | translate }}
      </a>
    </div>
  </h3>
  <div class="card-body search-form-top">
   <!-- Input -->
   <div class="row">
    <div class="input-group company-name">
      <input
        type="text"
        class="form-control"
        id="CompanyName"
        [(ngModel)]="CompanyName"
        maxlength="30"
        placeholder="{{
          'CODE_LIST.COMPANY_NAME_PLACEHOLDER' | translate
        }}"
      />
      <img *ngIf="!CompanyName" class="icon" src="assets/icons/book.svg" />
    </div>
    <div class="input-group telephone">
      <input
        type="text"
        class="form-control"
        id="Telephone"
        name="Telephone"
        [(ngModel)]="Telephone"
        placeholder="{{
          'CONTRACT_APP_LIST.TELEPHONE_PLACEHOLDER' | translate
        }}"
        maxlength="11"
        onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
      />
      <img *ngIf="!Telephone" class="icon" src="assets/icons/phone.svg" />
    </div>
    <div class="btn-search-left">
      <a target="_blank" class="btn-search" (click)="Search()"
        >{{ 'CODE_LIST.SEARCH_BUTTON' | translate }}
        <img
          class="search-icon"
          src="assets/icons/srch.svg"
        />
      </a>
    </div>
  </div>
  <!-- Limit 10000 text -->
  <label class="limit-500" id="limit10000">
    {{'CODE_LIST.SEARCH_LIMIT_10000' | translate}}
  </label>
    <!-- Radio -->
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">{{
              'COMMON.CASE_100' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width">
          <table class="table table-bordered" style="min-width: 800px;">
            <thead>
              <tr class="text-center">
                <th class="ftext">
                  {{ 'SALECOMPANY_LIST.REGISTRATION_DATE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'SALECOMPANY_LIST.COMPANY_NAME' | translate }}
                </th>
                <th class="ftext">
                  {{ 'SALECOMPANY_LIST.TYPE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'SALECOMPANY_LIST.TELEPHONE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'SALECOMPANY_LIST.EMAIL' | translate }}
                </th>
                <th class="ftext">
                  {{ 'SALECOMPANY_LIST.DETAILS' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of items" class="text-center">
                <td class="tbtext">{{ list.resist_date}}</td>
                <td class="tbtext">{{ list.company_name }}</td>
                <td class="tbtext">{{ getBusinessTypeLabel(list.business_type) }}</td>
                <td class="tbtext">{{ list.tel}}</td>
                <td class="tbtext">{{ list.mail_address }}</td>
                <td class="tbtext">
                  <a (click)="details(list.user_uuid)" class="btn-tb5">
                    {{ 'SALECOMPANY_LIST.DETAILS'| translate}}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>          
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;</span
          >
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{
              disabled: currentIndex == pageNumber || pageNumber == 0
            }"
            (click)="nextPage()"
            class="next"
            >&raquo;</span
          >
        </div>
        <div class="text-center" style="margin-top: 16px;max-width: 1500px;">
          <div class="col-12">
            <div class="display">
              <a (click)="csvOutput()" class="btn-search btn-csv csv-font">
                {{'COMMON.CSV' | translate}}
              </a>
              <i class="fa fa-print"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>