import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log } from 'src/providers/data/data';
@Component({
  selector: 'app-list-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ViewsErrorComponent implements OnInit {

  public error_code;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,) { }

  ngOnInit() {
    this.error_code = this.route.snapshot.paramMap.get('code');
    if (!this.error_code || this.error_code == 'undefined') {
      document.getElementById('err_title').style.display = 'block';
    } else {
      this.translate.get('ETITLE.' + this.error_code).subscribe(
        (res) => {
          document.getElementById('err_title').innerHTML = res
        }
      );
      document.getElementById('err_title').style.display = 'block';
      this.translate.get('ERROR.' + this.error_code).subscribe(
        (res) => {
          document.getElementById('err_text').innerHTML = res + '<br>' + '(' + this.error_code + ')'
        }
      );
      document.getElementById('err_text').style.display = 'block';
    }
    this.widthChanger('main-header', 762);
    this.widthChanger('content-wrapper', 762);
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }
}
