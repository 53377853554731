import { Injectable } from '@angular/core';
import { DataProvider } from '../data/data';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from '../../environments/environment';

@Injectable()
export class CallapiService {
  constructor(private data: DataProvider, public http: HttpClient) { }

  // B001
  public Get_Applications(
    uuid: string,
    company_name: string,
    telephone: string,
    app_date_from: string,
    app_date_to: string,
    acquired_name: string,
    judge_state: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: uuid,
        company_name: company_name,
        tel: telephone,
        app_date_from: app_date_from,
        app_date_to: app_date_to,
        acquired_name: acquired_name,
        judge_state: judge_state,
      },
    };
    return this.http.post(API_URL + '/applications/search/', body, {
      responseType: 'json',
      headers,
    });
  }
  // B002
  public Get_ApplicationsId(user_uuid: string, uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid,
      },
    };
    return this.http.post(API_URL + '/applications/search/id/', body, {
      responseType: 'json',
      headers,
    });
  }
  // B004
  public Update_Applications(
    user_uuid: string,
    uuid: string,
    corporate_status: string,
    company_name: string,
    company_name_kana: string,
    postcode: string,
    address_1: string,
    address_2: string,
    address_3: string,
    tel: string,
    staff_last_name: string,
    staff_first_name: string,
    staff_last_name_kana: string,
    staff_first_name_kana: string,
    staff_post: string,
    staff_position: string,
    judge_state: string,
    corporation_form: string,
    users: string,
    acquired_code: string,
    history_flg: string,
    mail_send_flg: boolean,
    business_type: string,
    health_code: string,
    paid_form: string,
    fees: number,
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid,
        corporate_status: corporate_status,
        company_name: company_name,
        company_name_kana: company_name_kana,
        postcode: postcode,
        address_1: address_1,
        address_2: address_2,
        address_3: address_3,
        tel: tel,
        staff_last_name: staff_last_name,
        staff_first_name: staff_first_name,
        staff_last_name_kana: staff_last_name_kana,
        staff_first_name_kana: staff_first_name_kana,
        staff_post: staff_post,
        staff_position: staff_position,
        judge_state: judge_state,
        corporation_form: corporation_form,
        users: users,
        acquired_code: acquired_code,
        history_flg: history_flg,
        mail_send_flg: mail_send_flg,
        business_type: business_type,
        health_code: health_code,
        paid_form: paid_form,
        fees: fees,
      },
    };
    return this.http.post(API_URL + '/applications/id/', body, {
      responseType: 'json',
      headers,
    });
  }
  // B005
  public Update_StatusId(
    user_uuid: string,
    uuid: string,
    Judge_Status: string,
    Judge_Ng_Cause: string,
    Judge_Disp_Cause: string,
    Note: string,
    // Fees: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid,
        judge_state: Judge_Status,
        judge_ng_cause: Judge_Ng_Cause,
        judge_disp_cause: Judge_Disp_Cause,
        note: Note,
        // fees: Fees,
      },
    };
    return this.http.post(API_URL + '/applications/status/id/', body, {
      responseType: 'json',
      headers,
    });
  }
  // B006
  public Get_AcquiredMaster(uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: uuid,
      },
    };
    return this.http.post(API_URL + '/applications/search/acquired/', body, {
      responseType: 'json',
      headers,
    });
  }
  // C001
  public Upload_Regcertificates(
    uuid: string,
    user_uuid: string,
    Pdf_Binary: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        uuid: uuid,
        user_uuid: user_uuid,
        pdf_binary: Pdf_Binary,
      },
    };
    return this.http.post(API_URL + '/regcertificates/upload/', body, {
      responseType: 'json',
      headers,
    });
  }
  // C002
  public Download_Regcertificates(
    user_uuid: string,
    uuid: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid,
      },
    };
    return this.http.post(API_URL + '/regcertificates/download/', body, {
      responseType: 'json',
      headers,
    });
  }
  // D001
  public Get_Screenauth(uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: uuid,
      },
    };
    return this.http.post(API_URL + '/screenauth/', body, {
      responseType: 'json',
      headers,
    });
  }
  // D003
  public Get_Authtypes(uuid: string, purpose: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: uuid,
        purpose: purpose
      }
    };
    return this.http.post(API_URL + '/authtypes/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E001
  public Get_Contracts(user_uuid: string, company_name: string, telephone: string, cancel_flg: string, management_company_code, business_type: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        company_name: company_name,
        tel: telephone,
        cancel_flg: cancel_flg,
        management_company_code: management_company_code,
        business_type: business_type,
      }
    };
    return this.http.post(API_URL + '/contracts/search/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E004
  public Get_WFAccount(user_uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid
      }
    };
    return this.http.post(API_URL + '/contracts/search/account/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E005
  public Update_Contracts(
    user_uuid: string,
    uuid: string,
    start_date: string,
    end_date: string,
    corporate_status: string,
    company_name: string,
    company_name_kana: string,
    postcode: string,
    address_1: string,
    address_2: string,
    address_3: string,
    tel: string,
    mail_address: string,
    staff_last_name: string,
    staff_first_name: string,
    staff_last_name_kana: string,
    staff_first_name_kana: string,
    staff_post: string,
    staff_position: string,
    corporation_form: string,
    users: string,
    acquired_code: string,
    history_flg: string,
    call_flg: string,
    health_code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid,
        start_date: start_date,
        end_date: end_date,
        corporate_status: corporate_status,
        company_name: company_name,
        company_name_kana: company_name_kana,
        postcode: postcode,
        address_1: address_1,
        address_2: address_2,
        address_3: address_3,
        tel: tel,
        mail_address: mail_address,
        staff_last_name: staff_last_name,
        staff_first_name: staff_first_name,
        staff_last_name_kana: staff_last_name_kana,
        staff_first_name_kana: staff_first_name_kana,
        staff_post: staff_post,
        staff_position: staff_position,
        corporation_form: corporation_form,
        users: users,
        acquired_code: acquired_code,
        history_flg: history_flg,
        call_flg: call_flg,
        health_code: health_code
      }
    };
    return this.http.post(API_URL + '/contracts/id', body, {
      responseType: 'json',
      headers,
    });
  }
  // E006
  public Send_ReStatus(
    user_uuid: string,
    corporation_no: string,
    corporate_status: string,
    company_name: string,
    mail_address: string,
    staff_last_name: string,
    staff_first_name: string,
    promotion_code1: string,
    promotion_code2: string,
    fees1:number,
    fees2:number,
    uuid: string,
    ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        corporation_no: corporation_no,
        corporate_status: corporate_status,
        company_name: company_name,
        mail_address: mail_address,
        staff_last_name: staff_last_name,
        staff_first_name: staff_first_name,
        promotion_code1: promotion_code1,
        promotion_code2: promotion_code2,
        fees1: fees1,
        fees2: fees2,
        uuid: uuid,
      }
    };
    return this.http.post(API_URL + '/contracts/status/id/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E007
  public Get_DivisionList(
    user_uuid: string,
    management_company_code: string,
    acquired_code: string,
    search_date: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        management_company_code: management_company_code,
        acquired_code: acquired_code,
        search_date: search_date
      }
    };
    return this.http.post(API_URL + '/contracts/search/division/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E008
  public Get_UnpaidAcquired(
    user_uuid: string,
    acquired_code: string,
    unpaid_only_flg: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data:{
        user_uuid: user_uuid,
        acquired_code: acquired_code,
        unpaid_only_flg: unpaid_only_flg
      }
    };
    return this.http.post(API_URL + '/contracts/search/unpaid/acquired/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E009
  public Get_UnpaidCorp(
    user_uuid: string,
    acquired_code: string,
    company_name: string,
    unpaid_only_flg: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data:{
        user_uuid: user_uuid,
        acquired_code: acquired_code,
        company_name: company_name,
        unpaid_only_flg: unpaid_only_flg
      }
    };
    return this.http.post(API_URL + '/contracts/search/unpaid/corporation/', body, {
      responseType: 'json',
      headers,
    });
  }
   // E010
   public Get_UnpaidUser(
    user_uuid: string,
    corporation_no: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data:{
        user_uuid: user_uuid,
        corporation_no: corporation_no
      }
    };
    return this.http.post(API_URL + '/contracts/search/unpaid/user/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E012
  public Get_WFAccountList(
    user_uuid: string,
    name: string,
    department_shop: string,
    mail_address: string,
    user_auth_type: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        name: name,
        department_shop: department_shop,
        mail_address: mail_address,
        user_auth_type: user_auth_type
      }
    };
    return this.http.post(API_URL + '/contracts/search/accountlist/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E013
  public Update_UnpaidApply(user_uuid: string, casio_id: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data:{
        user_uuid: user_uuid,
        casio_id: casio_id
      }
    };
    return this.http.post(API_URL + '/contracts/unpaid/apply/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E014
  public Download_Contracts(user_uuid: string, uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
        data:{
             user_uuid: user_uuid,
             uuid: uuid
        }                
    };
    return this.http.post(API_URL + '/contracts/download/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E015
  public Update_ContractsFlg(user_uuid: string, uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
        data:{
             user_uuid: user_uuid,
             uuid: uuid
        }                
    };
    return this.http.post(API_URL + '/contracts/updateflg/', body, {
      responseType: 'json',
      headers,
    });
  }
  //F003
  public Get_MembersAdmin (
    user_uuid: string,
    company_name: string,
    acquired_name: string,
    name: string,
    user_no: string,
    use_status: string,
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        company_name: company_name,
        acquired_name: acquired_name,
        name: name,
        user_no: user_no,
        use_status: use_status,
      }
    };
    return this.http.post(API_URL + '/members/admin/search', body, {
      responseType: 'json',
      headers,
    });
  }
  // I001
  public Create_CognitoUser(
    user_uuid: string,
    user_last_name: string,
    user_first_name: string,
    user_auth: string,
    mail_address: string,
    management_company_code: string,
    department_name: string,
    shop_name: string,
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        user_last_name: user_last_name,
        user_first_name: user_first_name,
        user_auth: user_auth,
        mail_address: mail_address,
        management_company_code: management_company_code,
        department_name: department_name,
        shop_name: shop_name
      }
    };
    return this.http.post(API_URL + '/cognito/user/createuser/', body, {
      responseType: 'json',
      headers,
    });
  }
  // I002
  public Update_CognitoUser(
    user_uuid: string,
    uuid: string,
    user_last_name: string,
    user_first_name: string,
    user_auth: string,
    mail_address: string,
    department_name: string,
    shop_name: string,
    management_company_code: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid,
        user_last_name: user_last_name,
        user_first_name: user_first_name,
        user_auth: user_auth,
        mail_address: mail_address,
        department_name: department_name,
        shop_name: shop_name,
        management_company_code: management_company_code
      }
    };
    return this.http.post(API_URL + '/cognito/user/updateuser/', body, {
      responseType: 'json',
      headers,
    });
  }
  // I005
  public Delete_CognitoUser(
    user_uuid: string,
    uuid: string,
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid
      }
    };
    return this.http.post(API_URL + '/cognito/user/deleteuser/', body, {
      responseType: 'json',
      headers,
    });
  }
  // I006
  public Resend_CognitoUser(
    user_uuid: string,
    uuid: string,
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid
      }
    };
    return this.http.post(API_URL + '/cognito/user/resenduser/', body, {
      responseType: 'json',
      headers,
    });
  }
  // I007
  public Activate_CognitoUser(
    crypto_uuid: string,
    crypto_pattern: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        crypto_uuid: crypto_uuid,
        crypto_pattern: crypto_pattern
      }
    };
    return this.http.post(API_URL + '/cognito/user/activateuser/', body, {
      responseType: 'json',
      headers,
    });
  }
  // I008
  public Update_WFCognitoMail(
    crypto_uuid: string,
    crypto_pattern: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        crypto_uuid: crypto_uuid,
        crypto_pattern: crypto_pattern
      }
    };
    return this.http.post(API_URL + '/cognito/user/wfchangemail/', body, {
      responseType: 'json',
      headers,
    });
  }
  // J001
  public Get_Notice(user_uuid: string, scope: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        scope: scope,
      },
    };
    return this.http.post(API_URL + '/notifications/', body, {
      responseType: 'json',
      headers,
    });
  }
  // K001
  public Get_ShopNames(uuid: string, acquired_code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: uuid,
        acquired_code: acquired_code
      }
    };
    return this.http.post(API_URL + '/shop/getnames/', body, {
      responseType: 'json',
      headers,
    });
  }
  // PostCode
  public ZipCode(PostCode: string): Observable<any> {
    return this.http.jsonp(
      'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + PostCode,
      'callback'
    );
  }
  // POINT API
  // API-001-01
  // public memberSelect1(memberID: string, datetime: string, hash: string, transactionID: string): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     authorization: localStorage.getItem('token'),
  //   });
  //   const body = {
  //       memberID: memberID,
  //       datetime: datetime,
  //       hash: hash,
  //       transactionID: transactionID
  //   };
  //   return this.http.post(PT_API_URL + '/memberSelect/id/', body, {
  //     responseType: 'json',
  //     headers,
  //   });
  // }
  // // API-001-02
  // public memberSelect2(datetime: string, hash: string, lastNameKanji: string, firstNameKanji: string,
  //   lastNameKana: string, firstNameKana: string, birthday: string, transactionID: string,): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     authorization: localStorage.getItem('token'),
  //   });
  //   const body = {
  //       datetime: datetime,
  //       hash: hash,
  //       lastNameKanji: lastNameKanji,
  //       firstNameKanji: firstNameKanji,
  //       lastNameKana: lastNameKana,
  //       firstNameKana: firstNameKana,
  //       birthday: birthday,
  //       transactionID: transactionID
  //   };
  //   return this.http.post(PT_API_URL + '/memberSelect/name/', body, {
  //     responseType: 'json',
  //     headers,
  //   });
  // }
  // // API-001-03
  // public contractSelect(memberID: string, datetime: string, hash: string, clubCode: string, transactionID: string): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     authorization: localStorage.getItem('token'),
  //   });
  //   const body = {
  //       memberID: memberID,
  //       datetime: datetime,
  //       hash: hash,
  //       clubCode: clubCode,
  //       transactionID: transactionID
  //   };
  //   return this.http.post(PT_API_URL + '/contractSelect/', body, {
  //     responseType: 'json',
  //     headers,
  //   });
  // }
  // // API-002-01
  // public getPoint(memberID: string, datetime: string, hash: string, price: number): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     authorization: localStorage.getItem('token'),
  //   });
  //   const body = {
  //       memberID: memberID,
  //       datetime: datetime,
  //       hash: hash,
  //       price: price
  //   };
  //   return this.http.post(BEA_POINT_API_URL + '/attendpt/getpoint/', body, {
  //     responseType: 'json',
  //     headers,
  //   });
  // }
  //  // API-003-01
  //  public managePoint(
  //    memberID: string,
  //    datetime: string,
  //    hash: string,
  //    clubCode: string,
  //    contractTypeCode: string,
  //    scode: string,
  //    svalue: string,
  //    point: number): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     authorization: localStorage.getItem('token'),
  //   });
  //   const body = {
  //       memberID: memberID,
  //       datetime: datetime,
  //       hash: hash,
  //       clubCode: clubCode,
  //       contractTypeCode: contractTypeCode,
  //       scode: scode,
  //       svalue: svalue,
  //       point: point
  //   };
  //   return this.http.post(BEA_POINT_API_URL + '/managepoint/add/', body, {
  //     responseType: 'json',
  //     headers,
  //   });
  // }

  // クーポン申請
  // M001
  public Get_Ticket_Payment(
    user_uuid: string,
    free_flg: string,
    OrderList: any
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        free_flg: free_flg,
        OrderList: OrderList
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/billamount', body, {
      responseType: 'json',
      headers,
    });
  }

  // M002
  public Create_CouponOrder(
    user_uuid: string,
    corporation_uuid: string,
    order_type: string,
    free_flg: string,
    OrderList: []
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        corporation_uuid: corporation_uuid,
        order_type: order_type,
        free_flg: free_flg,
        OrderList: OrderList
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder', body, {
      responseType: 'json',
      headers,
    });
  }

  // M003
  public Get_CouponOrder(
    user_uuid: string,
    scope: string,
    order_date_month: string,
    order_id: string,
    order_type: string,
    company_name: string,
    order_staff: string,
    order_coupon_status: string,
    order_payment_status: string,
    order_quotation: string,
    order_invoice: string,
    order_cancel_flg: string,
    deposit_date_from: string,
    deposit_date_to: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        scope: scope,
        order_date_month: order_date_month,
        order_id: order_id,
        order_type: order_type,
        company_name: company_name,
        order_staff: order_staff,
        order_coupon_status: order_coupon_status,
        order_payment_status: order_payment_status,
        order_quotation: order_quotation,
        order_invoice: order_invoice,
        order_cancel_flg: order_cancel_flg,
        deposit_date_from: deposit_date_from,
        deposit_date_to: deposit_date_to
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/search', body, {
      responseType: 'json',
      headers,
    });
  }

  // M004
  public Get_CouponOrderId(
    user_uuid: string,
    order_id: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        order_id: order_id
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/search/id', body, {
      responseType: 'json',
      headers,
    });
  }

  // M005
  public Update_CouponOrder_Cancel(
    user_uuid: string,
    order_id: string,
    order_cancel_info: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        order_id: order_id,
        order_cancel_info: order_cancel_info
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/cancel', body, {
      responseType: 'json',
      headers,
    });
  }

  // M006
  public Update_CouponOrder_PaymentStatus(
    user_uuid: string,
    order_id: string,
    deposit_date: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        order_id: order_id,
        deposit_date: deposit_date
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/payment/updatestatus', body, {
      responseType: 'json',
      headers,
    });
  }

  // M007
  public Create_Coupon(
    user_uuid: string,
    order_id: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        order_id: order_id
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/coupon', body, {
      responseType: 'json',
      headers,
    });
  }

  // M008
  public Get_CouponCode(
    user_uuid: string,
    scope: string,
    order_id: string,
    corporation_name: string,
    coupon_create_date_month: string,
    coupon_code_1: string,
    coupon_code_2: string,
    coupon_code_3: string,
    order_staff: string,
    coupon_unused : string,
    coupon_use : string,
    coupon_used : string,
    coupon_expired : string,
    coupon_revoked : string,
    coupon_refund : string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        scope: scope,
        order_id: order_id,
        corporation_name: corporation_name,
        coupon_create_date_month: coupon_create_date_month,
        coupon_code_1: coupon_code_1,
        coupon_code_2: coupon_code_2,
        coupon_code_3: coupon_code_3,
        order_staff: order_staff,
        coupon_status: {
            coupon_unused: coupon_unused,
            coupon_use: coupon_use,
            coupon_used: coupon_used,
            coupon_expired: coupon_expired,
            coupon_revoked: coupon_revoked,
            coupon_refund: coupon_refund
        }
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/coupon/id', body, {
      responseType: 'json',
      headers,
    });
  }

  // M009
  public Get_Product(
    user_uuid: string,
    path_type: string,
    outside_sale_info: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        path_type: path_type,
        outside_sale_info: outside_sale_info
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/search/product', body, {
      responseType: 'json',
      headers,
    });
  }

  // M010
  public Get_Ticket(
    user_uuid: string,
    scope: string,
    coupon_code_1: string,
    coupon_code_2: string,
    coupon_code_3: string,
    ticket_user_name: string,
    search_flg: number
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        scope: scope,
        coupon_code_1: coupon_code_1,
        coupon_code_2: coupon_code_2,
        coupon_code_3: coupon_code_3,
        ticket_user_name: ticket_user_name,
        search_flg: search_flg
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/search/ticket', body, {
      responseType: 'json',
      headers,
    });
  }

  // M011
  public Create_Invoice(
    user_uuid: string,
    order_id: string,
    bill_tax_free: number,
    bill_tax: number,
    TicketItem: any
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        order_id: order_id,
        ticket_info: {
          bill_tax_free: bill_tax_free,
          bill_tax: bill_tax,
          TicketItem: TicketItem
        }
      }      
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/create/invoice', body, {
      responseType: 'json',
      headers,
    });
  }

  // M012
  public Download_Quotation(
    user_uuid: string,
    order_id: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        order_id: order_id
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/download/quotation', body, {
      responseType: 'json',
      headers,
    });
  }

  // M013
  public Download_Invoice(
    user_uuid: string,
    order_id: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        order_id: order_id
      }
    };
    return this.http.post(API_URL + this.data.stub + '/couponorder/download/invoice', body, {
      responseType: 'json',
      headers,
    });
  }


  // 販売企業
  // N001
  public Get_SaleCompany(
    user_uuid: string,
    company_name: string,
    tel: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        company_name: company_name,
        tel: tel
      }
    };
    return this.http.post(API_URL + this.data.stub + '/salecompany/', body, {
      responseType: 'json',
      headers,
    });
  }

  // N002
  public Create_SaleCompany(
    user_uuid: string,
    corporate_status: string,
    company_name: string,
    company_name_kana: string,
    postcode: string,
    address_1: string,
    address_2: string,
    address_3: string,
    tel: string,
    mail_address: string,
    business_type: string,
    staff_last_name: string,
    staff_first_name: string,
    staff_last_name_kana: string,
    staff_first_name_kana: string,
    corporation_form: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        corporate_status: corporate_status,
        company_name: company_name,
        company_name_kana: company_name_kana,
        postcode: postcode,
        address_1: address_1,
        address_2: address_2,
        address_3: address_3,
        tel: tel,
        mail_address: mail_address,
        business_type: business_type,
        staff_last_name: staff_last_name,
        staff_first_name: staff_first_name,
        staff_last_name_kana: staff_last_name_kana,
        staff_first_name_kana: staff_first_name_kana,
        corporation_form: corporation_form
      }
    };
    return this.http.post(API_URL + this.data.stub + '/salecompany/create', body, {
      responseType: 'json',
      headers,
    });
  }

  // N003
  public Get_SaleCompanyId(
    user_uuid: string,
    id: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
        data: {
            user_uuid: user_uuid,
            id: id
        }
    };
    return this.http.post(API_URL + this.data.stub + '/salecompany/id', body, {
      responseType: 'json',
      headers,
    });
  }

  // N004
  public Update_SaleCompany(
    user_uuid: string,
    id: string,
    corporate_status: string,
    company_name: string,
    company_name_kana: string,
    postcode: string,
    address_1: string,
    address_2: string,
    address_3: string,
    tel: string,
    mail_address: string,
    business_type: string,
    staff_last_name: string,
    staff_first_name: string,
    staff_last_name_kana: string,
    staff_first_name_kana: string,
    corporation_form: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        id: id,
        corporate_status: corporate_status,
        company_name: company_name,
        company_name_kana: company_name_kana,
        business_type: business_type,
        postcode: postcode,
        address_1: address_1,
        address_2: address_2,
        address_3: address_3,
        tel: tel,
        mail_address: mail_address,
        staff_last_name: staff_last_name,
        staff_first_name: staff_first_name,
        staff_last_name_kana: staff_last_name_kana,
        staff_first_name_kana: staff_first_name_kana,
        corporation_form: corporation_form
      }
    };
    return this.http.post(API_URL + this.data.stub + '/salecompany/update', body, {
      responseType: 'json',
      headers,
    });
  }

  // N005
  public Get_CodeSaleCompany(
    user_uuid: string,
    company_name: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
        data: {
            user_uuid: user_uuid,
            company_name: company_name
        }
    };
    return this.http.post(API_URL + this.data.stub + '/salecompany/search/company', body, {
      responseType: 'json',
      headers,
    });
  }

  // L004
  public Create_OrdersCsvFile(
    user_uuid: string,
    scope: string,
    order_date_month: string,
    order_id: string,
    order_type: string,
    company_name: string,
    order_staff: string,
    order_coupon_status: string,
    order_payment_status: string,
    order_quotation: string,
    order_invoice: string,
    order_cancel_flg: string,
    deposit_date_from: string,
    deposit_date_to: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        scope: scope,
        order_date_month: order_date_month,
        order_id: order_id,
        order_type: order_type,
        company_name: company_name,
        order_staff: order_staff,
        order_coupon_status: order_coupon_status,
        order_payment_status: order_payment_status,
        order_quotation: order_quotation,
        order_invoice: order_invoice,
        order_cancel_flg: order_cancel_flg,
        deposit_date_from: deposit_date_from,
        deposit_date_to: deposit_date_to
      }
    };
    return this.http.post(API_URL + this.data.stub + '/csv/orders', body, {
      responseType: 'json',
      headers,
    });
  }

  // L005
  public Create_CouponsCsvFile(
    user_uuid: string,
    scope: string,
    order_id: string,
    corporation_name: string,
    coupon_create_date_month: string,
    coupon_code_1: string,
    coupon_code_2: string,
    coupon_code_3: string,
    order_staff: string,
    coupon_unused : string,
    coupon_use : string,
    coupon_used : string,
    coupon_expired : string,
    coupon_revoked : string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        scope: scope,
        order_id: order_id,
        corporation_name: corporation_name,
        coupon_create_date_month: coupon_create_date_month,
        coupon_code_1: coupon_code_1,
        coupon_code_2: coupon_code_2,
        coupon_code_3: coupon_code_3,
        order_staff: order_staff,
        coupon_status: {
            coupon_unused: coupon_unused,
            coupon_use: coupon_use,
            coupon_used: coupon_used,
            coupon_expired: coupon_expired,
            coupon_revoked: coupon_revoked
        }
      }
    };
    return this.http.post(API_URL + this.data.stub + '/csv/coupons', body, {
      responseType: 'json',
      headers,
    });
  }

  // L006
  public Create_TicketsCsvFile(
    user_uuid: string,
    scope: string,
    coupon_code_1: string,
    coupon_code_2: string,
    coupon_code_3: string,
    ticket_user_name: string,
    search_flg: number
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        scope: scope,
        coupon_code_1: coupon_code_1,
        coupon_code_2: coupon_code_2,
        coupon_code_3: coupon_code_3,
        ticket_user_name: ticket_user_name,
        search_flg: search_flg
      }
    };
    return this.http.post(API_URL + this.data.stub + '/csv/tickets', body, {
      responseType: 'json',
      headers,
    });
  }

}
