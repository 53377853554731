import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-order-apply-comp',
  templateUrl: './order-apply-comp.component.html',
  styleUrls: ['./order-apply-comp.component.scss'],
})
export class OrderApplyCompleteComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    public data: DataProvider
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-order-apply';
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'change-password-page'
    );
  }
  back() {
    this.router.navigate(['/wf-order-apply']);
  }
  
  backList() {
    this.router.navigate(['/wf-order-list']);
  }
  
}
