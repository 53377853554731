import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-salecompany-edit',
  templateUrl: './salecompany-edit.component.html',
  styleUrls: ['./salecompany-edit.component.scss'],
})
export class SaleCompanyEditComponent implements OnInit {
  public uuid;
  public Corporate_Status;
  public Corporation_Form;
  public Company_Name;
  public Company_Name_Kana;
  public Post_Code;
  public Address_1;
  public Address_2;
  public Address_3;
  public TelePhone;
  public Email;
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public business_type;
  public BusinessType = [];
  public business_name;  
  public notKana = false;
  public notFirstnameKana = false;
  public notLastnameKana = false;
  public Business_Type_Name;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    public data: DataProvider,
    private route: ActivatedRoute,
    public rest: CallapiService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService
  ) {
    this.BusinessType = [
      {code: "01", name: "農業・林業"},
      {code: "02", name: "漁業"},
      {code: "03", name: "鉱業，採石業，砂利，採取業"},
      {code: "04", name: "建設業"},
      {code: "05", name: "製造業"},
      {code: "06", name: "電気・ガス・熱供給・水道業"},
      {code: "07", name: "情報通信業"},
      {code: "08", name: "運輸業，郵便業"},
      {code: "09", name: "卸売業，小売業"},
      {code: "10", name: "金融業，保険業"},
      {code: "11", name: "不動産業，物品賃貸業"},
      {code: "12", name: "学術研究，専門・技術サービス業"},
      {code: "13", name: "宿泊業，飲食サービス業"},
      {code: "14", name: "生活関連サービス業，娯楽業"},
      {code: "15", name: "教育，学習支援業"},
      {code: "16", name: "医療，福祉"},
      {code: "17", name: "複合サービス事業"},
      {code: "18", name: "サービス業"},
      {code: "19", name: "公務"},
      {code: "20", name: "分類不能の産業"},
    ];
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-salecompany-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.widthChanger('main-header', 942);
    this.spinner.show();
    this.rest.Get_SaleCompanyId(this.data.uuid, this.uuid).subscribe(
      (res) => {
        console_log(res)
        this.Corporate_Status = res.data.corporate_status;
        this.Corporation_Form = res.data.corporation_form;
        this.Company_Name = res.data.company_name;
        this.Company_Name_Kana = res.data.company_name_kana;
        this.Post_Code = res.data.postcode;
        this.Address_1 = res.data.address_1;
        this.Address_2 = res.data.address_2;
        this.Address_3 = res.data.address_3;
        this.TelePhone = res.data.tel;
        this.Email = res.data.mail_address;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.business_type = res.data.business_type.length == 1 ? '0' + res.data.business_type : res.data.business_type;
        this.changeType(this.Corporation_Form);
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }
  getBusinessTypeName(code) {
    const business = this.BusinessType.find(b => b.code === code);
    return business ? business.name : '';
  }
  onKey(data) {
    this.rest.ZipCode(data).subscribe((res) => {
      if (res.results) {
        this.Address_1 =
          res.results[0].address1 +
          res.results[0].address2 +
          res.results[0].address3;
      }
    });
  }
  selectCorporation(id) {
    this.Corporate_Status = id;
  }
  changeType(type) {
    this.Corporation_Form = type;
    if (type == '1') {
      document.getElementById('type1').classList.remove('btn-box-grey');
      document.getElementById('type1').classList.add('btn-box');
      document.getElementById('type2').classList.remove('btn-box');
      document.getElementById('type2').classList.add('btn-box-grey');
      document.getElementById('type3').classList.remove('btn-box');
      document.getElementById('type3').classList.add('btn-box-grey');
    } else if (type == '2') {
      document.getElementById('type2').classList.remove('btn-box-grey');
      document.getElementById('type2').classList.add('btn-box');
      document.getElementById('type1').classList.remove('btn-box');
      document.getElementById('type1').classList.add('btn-box-grey');
      document.getElementById('type3').classList.remove('btn-box');
      document.getElementById('type3').classList.add('btn-box-grey');
    } else {
      document.getElementById('type3').classList.remove('btn-box-grey');
      document.getElementById('type3').classList.add('btn-box');
      document.getElementById('type2').classList.remove('btn-box');
      document.getElementById('type2').classList.add('btn-box-grey');
      document.getElementById('type1').classList.remove('btn-box');
      document.getElementById('type1').classList.add('btn-box-grey');
    }
  }

  back(id) {
    if (id == 1) {
      this.router.navigate(['/wf-salecompany-detail', { uuid: this.uuid }]);
    } else {
      document.getElementById('confirm').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      this.widthChanger('main-header', 942);
    }
  }
  
  dataValidation() {
    let err = false;
    let errTxt = '';
    let Number = /^-?\d*$/;
    if (this.notFirstnameKana && this.Name_Kana !== null && this.Name_Kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_NOT_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Name_Kana == null || this.Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notLastnameKana && this.Last_Name_Kana !== null && this.Last_Name_Kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_NOT_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name_Kana == null || this.Last_Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Name == null || this.Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name == null || this.Last_Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.business_type == null || this.business_type == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_BUSINESS_TYPE')
        .subscribe((res) => (errTxt = res));
    }
    if (!this.validateEmail(this.Email)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MAIL')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Email == null || this.Email == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MAIL')
        .subscribe((res) => (errTxt = res));
    }
    if (this.TelePhone == null || this.TelePhone == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE')
        .subscribe((res) => (errTxt = res));
    } else if (this.TelePhone != "" && !Number.test(this.TelePhone)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Address_2 == null || this.Address_2 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_2')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Address_1 == null || this.Address_1 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_1')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Post_Code == null || this.Post_Code == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE')
        .subscribe((res) => (errTxt = res));
    } else if (this.Post_Code != "" && !Number.test(this.Post_Code)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notKana === true && this.Company_Name_Kana !== null && this.Company_Name_Kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_NOT_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Company_Name_Kana == null || this.Company_Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Company_Name == null || this.Company_Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;

      header.style.minWidth = width.toString() + 'px';
    }
  }

  input() {
    if (this.dataValidation() == false) {
      return true;
    } else {
      this.Business_Type_Name = this.getBusinessTypeName(this.business_type);
      document.getElementById('confirm').style.display = 'block';
      document.getElementById('edit').style.display = 'none';
      document.getElementById('confirm').classList.remove('row1');
      document.getElementById('edit').classList.add('row');
      this.widthChanger('main-header', 752);
    }
  }

  confirm() {
    this.spinner.show();
    this.rest.Update_SaleCompany(
      this.data.uuid,
      this.uuid,
      this.Corporate_Status,
      this.Company_Name,
      this.Company_Name_Kana,
      this.Post_Code,
      this.Address_1,
      this.Address_2,
      this.Address_3,
      this.TelePhone,
      this.Email,
      this.business_type,
      this.Last_Name,
      this.Name,
      this.Last_Name_Kana,
      this.Name_Kana,
      this.Corporation_Form
    ).subscribe(
      (res) => {
        console_log(res);
        this.spinner.hide();
        this.router.navigate(['/wf-salecompany-edit-comp']);
      },
      (err) => {

        if (err.error.error) {
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
        } else {
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', { code: 'ERR_PDF' }]);
        }
      }
    );
  }

  onKeyKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notKana = true;
    } else {
      this.notKana = false;
    }
  }

  onKeyLastNameKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notLastnameKana = true;
    } else {
      this.notLastnameKana = false;
    }
  }

  onKeyNameKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notFirstnameKana = true;
    } else {
      this.notFirstnameKana = false;
    }
  }

  isKana(ch) {
    let k = 0;
    for (let i = 0; i < ch.length; i++) {
      if (ch[i] >= "\u30a0" && ch[i] <= "\u30ff") {
        k++;
      }
    }
    if (k == ch.length) {
      return true;
    }
    return false;
  }
  
  validateEmail(mail) {
    var reg = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    return reg.test(mail);
  }
}