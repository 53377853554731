import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
import { IDatePickerConfig } from 'ng2-date-picker';
import moment from 'moment';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/ja';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})

export class OrderListComponent implements OnInit {
  public order_date_month = '';
  public Today;
  public order_id = '';
  public order_type = '';
  public company_name = '';
  public order_staff = '';
  public order_coupon_status = '';
  public order_coupon_status0 = false;
  public order_coupon_status1 = false;
  public order_payment_status = '';
  public order_payment_status0 = false;
  public order_payment_status1 = false;
  public order_quotation = '';
  public order_quotation0 = false;
  public order_quotation1 = false;
  public order_invoice = '';
  public order_invoice0 = false;
  public order_invoice1 = false;
  public order_cancel_flg = '';
  public order_cancel_flg1 = false;
  public order_types = [
    { name: '本部', number: 1 },
    { name: '企業', number: 2 },
    { name: '個人', number: 3 }
  ];
  public deposit_date_from = '';
  public deposit_date_to = '';

  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-order-list';
    this.Today = new Date();
    const datepickerInputs = document.querySelectorAll('.datepicker-container input') as NodeListOf<HTMLInputElement>;

    datepickerInputs.forEach((input) => {
      input.style.width = '266px';
    });

    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        var pages = res.data;
        const page = pages.find(page => page.pagename === 'wf-order-list');
        var bool = page ? page.auth === "F" : false;
        if (!bool) {
          this.router.navigate(['/']);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.Search();
    this.widthChanger('main-header', 360);
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  Search() {
    this.order_coupon_status = this.checkBox(this.order_coupon_status0, this.order_coupon_status1);
    this.order_payment_status = this.checkBox(this.order_payment_status0, this.order_payment_status1);
    this.order_quotation = this.checkBox(this.order_quotation0, this.order_quotation1);
    this.order_invoice = this.checkBox(this.order_invoice0, this.order_invoice1);
    this.order_cancel_flg = this.order_cancel_flg1 ? "1" : "";
    this.spinner.show();
    this.rest.Get_CouponOrder(
      this.data.uuid,
      this.data.scope,
      this.order_date_month,
      this.order_id,
      this.order_type,
      this.company_name,
      this.order_staff,
      this.order_coupon_status,
      this.order_payment_status,
      this.order_quotation,
      this.order_invoice,
      this.order_cancel_flg,
      this.deposit_date_from == '' ? '' : this.datepipe.transform(this.deposit_date_from, 'yyyy-MM-dd'),
      this.deposit_date_to == '' ? '' : this.datepipe.transform(this.deposit_date_to, 'yyyy-MM-dd')
    ).subscribe(
      (res)=>{
          this.filteredItems = res.data.CouponOrder;
          if (res.code == 'W000001') {
            document.getElementById('limit10000').style.display = 'block';
          } else {
            document.getElementById('limit10000').style.display = 'none';
          }
          this.currentIndex = 1;
          this.pageStart = 1;
          this.pages = 6;

          this.pageNumber = parseInt(
            '' + this.filteredItems.length / this.pageSize
          );
          if (this.filteredItems.length % this.pageSize != 0) {
            this.pageNumber++;
          }
          if (this.pageNumber < this.pages) {
            this.pages = this.pageNumber;
          }
          this.refreshItems();
          this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  details(order_id) {
    this.router.navigate(['/wf-order-list-detail', { order_id }]);
  }
  codeList(list) {
    console_log(list);
    if (list.coupon_flg == '1') {
      this.router.navigate(['/wf-code-list', {order_id: list.order_id}]);
    }
  }
  pdf_download(uuid) {
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }

  getOrderTypeName(number: string): string | undefined {
    const orderType = this.order_types.find(type => type.number === Number(number));
    return orderType ? orderType.name : undefined;
  }
  parse(e) {
    return parseFloat(e).toLocaleString('en');
  }
  getPaymentStatusName(number: string){
    return number == '0' ? '未入金' : '入金済み';
  }
  getCouponflgName(number: string){
    return number == '0' ? '未発行' : '発行済み';
  }
  checkBox(check0, check1) {
    var value = "ALL"
    if (check0 && check1) {
      value = "ALL"
    } else if (check0) {
      value = "0";
    } else if (check1) {
      value = "1"
    }
    return value;
  }

  csvOutput() {
    this.spinner.show();
    this.rest.Create_OrdersCsvFile(
      this.data.uuid,
      this.data.scope,
      this.order_date_month,
      this.order_id,
      this.order_type,
      this.company_name,
      this.order_staff,
      this.order_coupon_status,
      this.order_payment_status,
      this.order_quotation,
      this.order_invoice,
      this.order_cancel_flg,
      this.deposit_date_from == '' ? '' : this.datepipe.transform(this.deposit_date_from, 'yyyy-MM-dd'),
      this.deposit_date_to == '' ? '' : this.datepipe.transform(this.deposit_date_to, 'yyyy-MM-dd')
    ).subscribe(
      (res) => {
        console_log(res);
        const link = document.createElement('a');
        link.href = res.data.s3url;
        link.click();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  config: IDatePickerConfig = {
    format: 'yyyy-MM',
    yearFormat: 'yyyy' + '年',
    disableKeypress: true,
    onOpenDelay: 0,
    closeOnSelect: true,
    monthFormat: 'MM',
    monthBtnFormat: 'MM' + '月',
  }
  GoToCurrent(event) {
    event = moment().subtract(0, 'days');
    this.order_date_month = event;
  }

  onDateChange(event: any): void {
    console_log(event);
    this.order_date_month = event;
    if(this.order_date_month != ''){
      document.getElementById('monthclear').style.display = 'block';
      document.getElementById('month').style.display = 'none';
    }else{
      document.getElementById('monthclear').style.display = 'none';
      document.getElementById('month').style.display = 'block';
    }
  }

  clearDate() {
    this.order_date_month = '';
    if(this.order_date_month == ''){
      document.getElementById('monthclear').style.display = 'none';
      document.getElementById('month').style.display = 'block';
    }
  }

  @ViewChild('date1') date1: ElementRef;
  @ViewChild('date2') date2: ElementRef;

  resetDate(id) {
    console_log(id)
    if(id == 1){
      if(this.deposit_date_from != ''){
        this.date1['displayValue'] = '';
        this.deposit_date_from = '';
        console_log(this.deposit_date_from)
        if(this.deposit_date_from == ''){
          document.getElementById('clear1').style.display = 'none';
          document.getElementById('calendar1').style.display = 'block';
        }
      }
    }else{
      if(this.deposit_date_to != ''){
        this.date2['displayValue'] = '';
        this.deposit_date_to = '';
        console_log(this.deposit_date_to)
        if(this.deposit_date_to == ''){
          document.getElementById('clear2').style.display = 'none';
          document.getElementById('calendar2').style.display = 'block';
        }
      }
    }
  }

  Calendar(id){
    if(id == 1){
      if(this.deposit_date_from != ''){
        document.getElementById('clear1').style.display = 'block';
        document.getElementById('calendar1').style.display = 'none';
        console_log(this.deposit_date_from);
      }else{
        document.getElementById('clear1').style.display = 'none';
        document.getElementById('calendar1').style.display = 'block';
        console_log(this.deposit_date_from);
      }
    }else{
      if(this.deposit_date_to != ''){
        document.getElementById('clear2').style.display = 'block';
        document.getElementById('calendar2').style.display = 'none';
      }else{
        document.getElementById('clear2').style.display = 'none';
        document.getElementById('calendar2').style.display = 'block';
      }
    }
  }

  optionsFrom: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: '入金日/決済日FROM', // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'yyyy-MM-dd', // date format to display in input
    formatTitle: 'yyyy'+'年'+'MM'+'月',
    formatDays: 'EEEEE',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: locale, // date-fns locale
    position: 'bottom',
    inputClass: '', // custom input CSS class to be applied
    calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#E10800', // in case you customize you theme, here you define scroll bar color
  };
  optionsTo: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: '入金日/決済日TO', // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'yyyy-MM-dd', // date format to display in input
    formatTitle: 'yyyy'+'年'+'MM'+'月',
    formatDays: 'EEEEE',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: locale, // date-fns locale
    position: 'bottom',
    inputClass: '', // custom input CSS class to be applied
    calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#E10800', // in case you customize you theme, here you define scroll bar color
  };
}