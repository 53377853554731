import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss'],
})

export class TicketListComponent implements OnInit {
  public coupon_code = '';
  public coupon_code_1 = '';
  public coupon_code_2 = '';
  public coupon_code_3 = '';
  public ticket_user_name = '';
  public search_flg: number = 1;
  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-ticket-list';
    this.coupon_code = this.route.snapshot.paramMap.get('coupon_code');
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        var pages = res.data;
        const page = pages.find(page => page.pagename === 'wf-ticket-list');
        var bool = page ? page.auth === "F" : false;
        if (!bool) {
          this.router.navigate(['/']);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    if (this.coupon_code) {
      this.coupon_code_1 = this.coupon_code.substring(0,4);
      this.coupon_code_2 = this.coupon_code.substring(4,8);
      this.coupon_code_3 = this.coupon_code.substring(8,12);
      this.Search();
    }
    this.widthChanger('main-header', 360);
  }

  SearchCheck() {
    if (this.search_flg == 1) {
      if (this.coupon_code_1 != '' || this.coupon_code_2 != '' || this.coupon_code_3 != '') {
        return false;
      } else {
        return true;
      }
    }
    if (this.search_flg == 2) {
      if (this.ticket_user_name == '') {
        return true;
      } else {
        return false
      }
    }
    return false;
  }

  Search() {
    if (!this.SearchCheck()) {
      this.spinner.show();
      this.rest.Get_Ticket(
        this.data.uuid,
        this.data.scope,
        this.coupon_code_1,
        this.coupon_code_2,
        this.coupon_code_3,
        this.ticket_user_name,
        this.search_flg
      ).subscribe(
        (res)=>{
          console_log(res);
          this.filteredItems = res.Tickets;
          if (res.code == 'W000001') {
            document.getElementById('limit10000').style.display = 'block';
          } else {
            document.getElementById('limit10000').style.display = 'none';
          }
          this.currentIndex = 1;
          this.pageStart = 1;
          this.pages = 6;

          this.pageNumber = parseInt(
            '' + this.filteredItems.length / this.pageSize
          );
          if (this.filteredItems.length % this.pageSize != 0) {
            this.pageNumber++;
          }
          if (this.pageNumber < this.pages) {
            this.pages = this.pageNumber;
          }
          this.refreshItems();
          this.spinner.hide();
        },
        (err)=>{
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        }
      );
    }
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  
  csvOutput() {
    if (!this.SearchCheck()) {
      this.spinner.show();
      this.rest.Create_TicketsCsvFile(
        this.data.uuid,
        this.data.scope,
        this.coupon_code_1,
        this.coupon_code_2,
        this.coupon_code_3,
        this.ticket_user_name,
        this.search_flg
      ).subscribe(
        (res) => {
          console_log(res);
          const link = document.createElement('a');
          link.href = res.data.s3url;
          link.click();
          this.spinner.hide();
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        }
      );
    }
  }

  searchFLG(flg: number) {
    this.search_flg = flg;
  }
}