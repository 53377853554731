<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'CODE_APPLY.TITLE' | translate }}</h3>  
  <!-- Section №1 -->
  <div id="edit">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'CODE_APPLY.ISSUED' | translate }}</p>
      <p class="card-title">{{ 'CODE_APPLY.COMPANY_NAME' | translate }}</p>
      <div class="row" style="margin-top: 20px">
        <div class="input-group" style="margin-left: 15px; width: 199px">
          <input
            type="text"
            class="form-control"
            id="CompanyName"
            [(ngModel)]="CompanyName"
            maxlength="20"
            placeholder="{{
              'CODE_APPLY.COMPANY_NAME_PLACEHOLDER' | translate
            }}"
          />
          <img *ngIf="!CompanyName" class="icon" src="assets/icons/book.svg" />
        </div>
        <div class="search-button">
          <a target="_blank" class="btn-search" (click)="Search()" [ngClass]="{'btn-search-disable': CompanyName == ''}"
            >{{ 'CODE_APPLY.SEARCH_BUTTON' | translate }}
            <img
              class="search-icon"
              src="assets/icons/srch.svg"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="company_uuid">
      <section class="content">
        <div class="container-fluid">
          <div class="row table-width">
            <table class="table table-bordered" style="min-width: 640px;">
              <tbody>
                <tr *ngFor="let c of Companies">
                  <td class="table-radio">
                    <input
                      type="radio"
                      name="company"
                      value="{{c.company_uuid}}"
                      [(ngModel)]="company_uuid"
                      (input)="onChangeCompany(c.company_name)"
                    >
                  </td>
                  <td>
                    {{c.company_name}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
    <div class="card-body">
      <p class="card-title">{{ 'CODE_APPLY.1DAYPASS_PURCHASED_TICKETS' | translate }}</p>
    </div>
    <div class="card-body">
      <section class="content">
        <div class="container-fluid">
          <div class="row" style="max-width: 800px;">
            <table class="table item-table">
              <thead>
                <tr class="text-center">
                  <th>{{ 'CODE_APPLY.TH_PRODUCT_NAME' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_UNIT_PRICE' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_PIECES' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_SUBTOTAL' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let single of singleItem; let i = index" class="text-center">
                  <td>{{ single.product_name }}</td>
                  <td>{{ parse(single.unit_price) }}</td>
                  <td>
                    <div class="custom-control display-flex">
                      <input 
                        type="text" 
                        class="form-control sold-input"
                        placeholder="1"
                        [value]=""
                        maxlength="5"
                        (input)="onChangeSingle($event, i, single)"
                        onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"  
                      >
                      <span>{{ 'CODE_APPLY.PIECES' | translate }}</span>
                    </div>
                  </td>
                  <td>{{parse(singleSubTotals[i])}}</td>
                </tr>
                <tr *ngFor="let set of setItem; let i = index" class="text-center">
                  <td>{{ set.product_name }}</td>
                  <td>{{ parse(set.unit_price) }}</td>
                  <td>
                    <div class="custom-control display-flex">
                      <input 
                        type="text" 
                        class="form-control sold-input"
                        placeholder="1"
                        [value]=""
                        maxlength="5"
                        (input)="onChangeSet($event, i, set)"
                        onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"  
                      >
                      <span>{{ 'CODE_APPLY.SET' | translate }}</span>
                    </div>
                  </td>
                  <td>{{parse(setSubTotals[i])}}</td>
                </tr>
              </tbody>
            </table>
            <div class="total">
              <div class="total-piece">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_PURCHASE_QUANTITY' | translate }}</h3>
                <h3 class="card-title"><span>{{totalPurchase}}{{ 'CODE_APPLY.PIECES' | translate }}</span></h3>
              </div>
              <div class="total-amount">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_AMOUNT' | translate }}</h3>
                <h3 class="card-title"><span>{{parse(totalAmount)}}</span></h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- check -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_APPLY.FREE_DISTRIBUTION' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
              <div class="custom-control display-flex col-8 col-md-6 col-xl-6">
                <label class="check-previous-text card-title" for="vehicle3"> {{ 'CODE_APPLY.FREE_DISTRIBUTION_CHARGE' | translate }}</label>
                  <input  class="custom-control-input 
                          custom-control-input-danger
                          custom-control-input-outline"
                          type="checkbox" 
                          id="free_flg" 
                          [(ngModel)]="free_flg"
                          (change)="onChangeFree()">
                  <label for="free_flg" style="margin-left: 40px;" class="custom-control-label card-title"></label>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button -->
    <div class="text-center">
      <!-- Error text -->
      <div class="text1 text-center" style="height: 34px">
        <p style="display: none;" id="error"></p>
      </div>
      <div class="two-btn">
        <div class="display">
          <a (click)="Next()" class="btn-log">
            {{ 'CODE_APPLY.CONFIRM_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
  </div>
  <!-- End Section №1 -->
   
  <!-- Section №2 -->
  <section class="content" id="confirm" style="display: none;">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'CODE_APPLY.CONFIRM' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_APPLY.COMPANY_NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <h3 class="card-title">{{company_name}}</h3>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_APPLY.1DAYPASS_PURCHASED_TICKETS' | translate }}</h3>
        </div>
        <!-- <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <h3 class="card-title">{{total_ticket_num}}{{ 'CODE_APPLY.PIECES' | translate }}</h3>
        </div> -->
      </div>
    </div>
    <div class="card-body">
      <section class="content">
        <div class="container-fluid">
          <div class="row" style="max-width: 800px;">
            <table class="table item-table confirm-table">
              <thead>
                <tr class="text-center">
                  <th>{{ 'CODE_APPLY.TH_PRODUCT_NAME' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_UNIT_PRICE' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_PIECES' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_SUBTOTAL' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let single of singleItemConfirm; let i = index" class="text-center">
                  <td>{{ single.product_name }}</td>
                  <td>{{ parse(single.bill_unit_price_tax) }}</td>
                  <td>{{ single.value}}{{ 'CODE_APPLY.PIECES' | translate }}</td>
                  <td>{{ parse(single.bill_tax_free) }} <span>(税込{{ parse(single.bill_tax) }})</span></td>
                </tr>
                <tr *ngFor="let set of setItemConfirm; let i = index" class="text-center">
                  <td>{{ set.product_name }}</td>
                  <td>{{ parse(set.bill_unit_price_tax) }}</td>
                  <td>{{ set.value }}{{ 'CODE_APPLY.SET' | translate }}</td>
                  <td>{{ parse(set.bill_tax_free) }} <span>(税込{{ parse(set.bill_tax) }})</span></td>
                </tr>
              </tbody>
            </table>
            <div class="total">
              <div class="total-piece">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_PURCHASE_QUANTITY' | translate }}</h3>
                <h3 class="card-title"><span>{{totalPurchase}} <span>{{ 'CODE_APPLY.PIECES' | translate }}</span> </span></h3>
              </div>
              <div class="total-amount">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_AMOUNT' | translate }}</h3>
                <h3 class="card-title" style="padding-right: 0px;"><span>{{parse(total_bill_tax_free)}}</span></h3>
                <h3 class="card-title bill-tax">(税込{{parse(total_bill_tax)}})</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_APPLY.FREE_DISTRIBUTION' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">対象外</h3>
        </div>
      </div>
    </div>
    <!-- Button -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="apply()" class="btn-pink">{{ 'CODE_APPLY.APPLY_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
  </section>
  <!-- End Section №2 -->
</div>
<!-- Loader -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#E10800 " type="square-jelly-box" [fullScreen]="true">
  <p style="color: #E10800 "> {{ 'COMMON.LOADING' | translate }} </p>
</ngx-spinner>